import React, { Component } from "react";
import { Drawer, List } from "rsuite";
import LeadListItem from "../../../../components/LeadListItem";
import HomeLeadView from "../home-lead-view";

class UserLeadFollowups extends Component {
  state = {
    selected: null,
  };
  render() {
    var { selected } = this.state;
    return (
      <div>
        <List>
          {this.props?.followups?.map((followup, index) => {
            var lead = followup.lead_spot;
            if (lead) {
              lead.last_followup = followup;
            }
            return (
              <List.Item>
                <LeadListItem
                  lead={lead}
                  key={index}
                  index={index}
                  onClick={() => {
                    this.setState({ selected: lead });
                  }}
                />
              </List.Item>
            );
          })}
        </List>

        <Drawer
          open={selected != null}
          onClose={() => this.setState({ selected: null })}
          placement="bottom"
          size="full"
        >
          <Drawer.Header>
            <Drawer.Title>View {selected?.shop_name}</Drawer.Title>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {selected && (
              <HomeLeadView
                lead={selected}
                back={() => this.setState({ selected: null })}
                next={() => {}}
                previous={() => {}}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(selected) => this.setState({ selected })}
              />
            )}
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default UserLeadFollowups;
