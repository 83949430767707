import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Modal,
  Loader,
  Input,
  InputNumber,
  DatePicker,
  InputPicker,
  List,
  Stack,
  Placeholder,
  Tag,
  Nav,
  Rate,
  Navbar,
  Toggle,
  Badge,
  Drawer,
  Divider,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
  shortenedText,
} from "../../../constants";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import FunnelIcon from "@rsuite/icons/Funnel";
import HomeLeadView from "./home-lead-view";
import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import { loadRoutes, loadRouteWalks } from "../../../Loaders/GeneralLoder";
import { checkRoute } from "../../../Helpers/RouteHelper";
import RouteWalker from "../../../Modules/RouteWalker";
import CustomerView from "./customer-view";
import {
  getNearestItems,
  getRouteZones,
} from "../../../Helpers/CoordinateHelper";

class HomeLeadMap extends Component {
  state = {
    selectedCustomer: null,
    selectedLead: null,
  };

  componentDidMount = () => {
    this.props.quickLeadViewFunction((lead) =>
      this.setState({ selectedLead: lead })
    );
    this.props.quickCustomerViewFunction((customer) =>
      this.setState({ selectedCustomer: customer })
    );
  };

  filterVisibleLeadMarkers = () => {
    const { mapObject, leads } = this.props;
    if (!this.props.filterMapDeepZoom) {
      return leads;
    }
    if (!mapObject) return [];

    const bounds = mapObject.getBounds();
    if (!bounds) return [];

    const zoom = mapObject.getZoom();

    const visibleLeads = leads.filter((lead) => {
      const position = JSON.parse(lead.geolocation);
      return bounds.contains(position) && zoom >= 15;
    });

    return visibleLeads;
  };

  render() {
    var { selectedCustomer, selectedLead } = this.state;
    var {
      mapObject,
      leads,
      customers,
      routes,
      onLoad,
      onUnmount,
      selectedRouteId,
      selectedRoute,
      actualRoute,
      routeWalks,
      currentLocation,
      loadWalks,
    } = this.props;

    var nearCustomers = mapObject
      ? getNearestItems(mapObject.getCenter(), customers, "geo_location", 15)
      : [];

    return (
      <div>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: `calc(100vh - ${
              this.props.filterMapMiniMap ? "350px" : "280px"
            })`,
          }}
          zoom={10}
          onLoad={(map) => {
            onLoad(map);
            window.leadMap = map;
          }}
          onUnmount={onUnmount}
          mapTypeId="roadmap"
          options={{
            streetViewControl: true,
          }}
          onPovChanged={(pov) => console.log(pov)}
        >
          {this.props.placeMarkers && this.props.placeMarkers(mapObject)}
          {this.props.filterMapLeads && (
            <MarkerClustererF>
              {(clusterer) =>
                this.filterVisibleLeadMarkers().map((lead, index) => (
                  <MarkerF
                    key={`lead-${index}`}
                    position={JSON.parse(lead.geolocation)}
                    label={{
                      text: "L",
                      fontSize: "10",
                    }}
                    onClick={() => {
                      alertInfoSilent(lead.shop_name, 3000, () =>
                        this.setState({ selectedLead: lead })
                      );
                      return false;
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/search/?api=1&query=${
                        JSON.parse(lead.geolocation).lat
                      },${JSON.parse(lead.geolocation).lng}`)
                    }
                    clusterer={this.props.filterMapCluster ? clusterer : null}
                  />
                ))
              }
            </MarkerClustererF>
          )}

          {this.props.filterMapCustomers && (
            <MarkerClustererF>
              {(clusterer) =>
                nearCustomers?.map((customer, index) => (
                  <MarkerF
                    key={`customer-${index}`}
                    position={JSON.parse(customer.geo_location)}
                    label={{
                      text: "C",
                      fontSize: "7",
                    }}
                    onClick={() => {
                      alertInfoSilent(
                        customer.shop_name + " (View)",
                        3000,
                        () => this.setState({ selectedCustomer: customer })
                      );
                      return false;
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/search/?api=1&query=${
                        JSON.parse(customer.geo_location).lat
                      },${JSON.parse(customer.geo_location).lng}`)
                    }
                    clusterer={this.props.filterMapCluster ? clusterer : null}
                  />
                ))
              }
            </MarkerClustererF>
          )}

          {this.props.filterMapRoutes && (
            <>
              <PolygonF
                options={{
                  paths: JSON.parse(
                    routes.find((r) => r.id == selectedRouteId)?.cord1
                  ).map((coord) => ({
                    lat: parseFloat(coord.lat),
                    lng: parseFloat(coord.lng),
                  })),
                  strokeColor: "#1abc9c",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: 0,
                }}
              />
              <PolylineF
                path={
                  getRouteZones(routes.find((r) => r.id == selectedRouteId))
                    ?.verticalLine
                }
                options={{
                  strokeColor: "#697987",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
              <PolylineF
                path={
                  getRouteZones(routes.find((r) => r.id == selectedRouteId))
                    ?.horizontalLine
                }
                options={{
                  strokeColor: "#697987",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            </>
          )}

          {this.props.filterMapWalks &&
            routeWalks.map((routeWalk, index) => (
              <Polyline
                key={index}
                path={JSON.parse(routeWalk?.cords)}
                options={{
                  strokeColor: "#00FF00",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}

          <MarkerF
            position={currentLocation}
            label={{
              text: "You",
              fontSize: "10",
            }}
          />
        </GoogleMap>

        {this.props.filterMapRouteWalker && (
          <RouteWalker
            routes={routes}
            mapObject={mapObject}
            currentLocation={currentLocation}
            sessionCheck={this.props.sessionCheck}
            onCreate={loadWalks}
            selectedRoute={selectedRoute}
            routeWalks={routeWalks}
            actualRoute={actualRoute}
            filterMapMiniMap={this.props.filterMapMiniMap}
            filterMapMiniMapEdit={this.props.filterMapMiniMapEdit}
            nearestCustomers={this.props.nearestCustomers}
            nearestLeads={this.props.nearestLeads}
          />
        )}

        {/* CustoemrDrawer */}
        {selectedCustomer && (
          <Drawer
            open={selectedCustomer}
            onClose={() => this.setState({ selectedCustomer: null })}
            size="full"
            placement="bottom"
          >
            <Drawer.Header>
              <Drawer.Title>
                Customer: {selectedCustomer.shop_name}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <CustomerView
                data={this.state.selectedCustomer}
                back={() => this.setState({ selectedCustomer: null })}
                currentLocation={currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            </Drawer.Body>
          </Drawer>
        )}

        {/* LeadViewDrawer */}
        {selectedLead && (
          <Drawer
            open={selectedLead}
            onClose={() => this.setState({ selectedLead: null })}
            size="full"
            placement="bottom"
          >
            <Drawer.Header>
              <Drawer.Title>Lead: {selectedLead.shop_name}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <HomeLeadView
                lead={selectedLead}
                back={() => this.setState({ selectedLead: null })}
                changeVal={this.props.changeVal}
                remarks={this.props.remarks}
                saveLead={this.props.saveLead}
                currentLocation={currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(selectedLead) => this.setState({ selectedLead })}
                routes={routes}
                loadLeads={this.loadLeads}
              />
            </Drawer.Body>
          </Drawer>
        )}
      </div>
    );
  }
}

export default HomeLeadMap;
