import React, { Component } from "react";
import { Divider, List, Tag } from "rsuite";
import SalesQtyList from "../../../../Modules/SalesQtyList";

class UserPayments extends Component {
  state = {};
  render() {
    var { payments } = this.props;
    return (
      <div>
        <Divider>Payments Summary</Divider>

        <p>
          <strong>Cash: </strong>
          {payments.reduce(
            (total, payment) =>
              payment.type === "Cash" ? total + payment.amount : total,
            0
          )}
        </p>
        <p>
          <strong>UPI / Bank: </strong>
          {payments.reduce(
            (total, payment) =>
              payment.type === "UPI" ? total + payment.amount : total,
            0
          )}
        </p>
        <p>
          <strong>Online: </strong>
          {payments.reduce(
            (total, payment) =>
              payment.type === "Online" ? total + payment.amount : total,
            0
          )}
        </p>
        <p>
          <strong>Total: </strong>
          {payments.reduce((total, payment) => total + payment.amount, 0)}
        </p>

        <Divider>Quantities as per Payments</Divider>
        <SalesQtyList payments={payments} label="Click to calculate QTY." />

        <br />
        <List size="lg" hover>
          {payments.map((payment, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(payment.sale.customer)}
              >
                <strong>{payment.sale.customer.shop_name}</strong>
                <br />
                <strong>Invoice # {payment.sale.id}</strong> -{" "}
                {payment.sale.created_at} <br /> Created by:{" "}
                {payment.sale.created_by.name}
                <br />
                <Tag>
                  {payment.sale.items.length} Items |{" "}
                  {payment.sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Pcs |{" "}
                  {payment.sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      parseFloat(currentValue?.unit_price) *
                        parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Rupees
                </Tag>
                <br />
                <strong>Status: {payment.sale.status}</strong>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserPayments;
