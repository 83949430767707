import React, { Component } from "react";
import { List, Tag, Badge } from "rsuite";
import moment from "moment";

class UserLeads extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg">
          {data.lead_spots.map((lead, index) => {
            return (
              <List.Item key={index} index={index}>
                <strong>Shop name: {lead.shop_name}</strong> <br />{" "}
                <strong>
                  Contact: <a href={`tel:${lead.phone}`}>{lead.phone}</a>
                </strong>
                <br /> <i>{lead.remarks}</i>
                <br />
                <Tag>Created: {moment(lead.created_at).fromNow()}</Tag>
                <Tag color="yellow">
                  Next Visit: {moment(lead.next_visit).fromNow()}
                </Tag>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserLeads;
