import Cookies from "js-cookie";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { alertInfo } from "../constants";
import { stringSimilarity } from "string-similarity-js";

export function getProgressColor(progress) {
  // Convert progress to a value between 0 and 1
  const normalizedProgress = progress / 100;

  // Define color ranges
  const startColor = [255, 0, 0]; // Red for lower progress
  const endColor = [0, 255, 0]; // Green for higher progress

  // Interpolate between startColor and endColor based on progress
  const color = startColor.map((channel, index) => {
    return Math.round(
      channel + (endColor[index] - channel) * normalizedProgress
    );
  });

  // Construct color string in RGB format
  const rgbColor = `rgb(${color.join(",")})`;

  return rgbColor;
}

export function confirm(message, yes, no = null) {
  var conf = window.confirm(message);
  if (conf) {
    yes();
  } else if (no) {
    no();
  }
  // confirmAlert({
  //   title: "Confirm to submit",
  //   message,
  //   buttons: [
  //     {
  //       label: "Yes",
  //       onClick: () => {
  //         yes();
  //       },
  //     },
  //     {
  //       label: "No",
  //       onClick: () => {
  //         if (no) {
  //           no();
  //         }
  //       },
  //     },
  //   ],
  // });
}

export function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard:", text);
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard:", err);
    });
  alertInfo("Copied to clipboard");
}

export function getFavCustomers(onDone) {
  const favsJSON = Cookies.get("favs");

  if (favsJSON) {
    const favs = JSON.parse(favsJSON);
    onDone(favs);
    return favs;
  } else {
    const favs = [];
    onDone(favs);
    return favs;
  }
}

export function setFavCustomer(customer, onDone) {
  const favsJSON = Cookies.get("favs") || "[]";
  const favs = JSON.parse(favsJSON);

  const favIndex = favs.indexOf(customer.id);

  if (favIndex !== -1) {
    favs.splice(favIndex, 1);
  } else {
    favs.push(customer.id);
  }

  // Convert the updated favs array back to JSON and store it in cookies
  Cookies.set("favs", JSON.stringify(favs), { expires: 365 });

  // Update the state with the new favs array
  onDone(favs);
}

export function findSuitableHashTags(
  hashTags,
  stringArray,
  similarityThreshold = 0.5
) {
  const suitableHashTags = [];
  const addedHashTags = new Set();

  // Iterate over each string in the stringArray
  stringArray.forEach((str) => {
    let maxSimilarity = -1;
    let mostSimilarHashTag = null;

    // Iterate over each hash tag to find the most similar one
    hashTags.forEach((hashTag) => {
      // Skip hash tags that have already been added
      if (addedHashTags.has(hashTag.name)) return;

      const similarity = stringSimilarity(str, hashTag.name);

      // Update the most similar hash tag if the similarity is higher
      if (similarity > maxSimilarity) {
        maxSimilarity = similarity;
        mostSimilarHashTag = hashTag.name;
      }
    });

    // Add the most similar hash tag to the suitableHashTags array
    if (maxSimilarity >= similarityThreshold && mostSimilarHashTag) {
      suitableHashTags.push(mostSimilarHashTag);
      addedHashTags.add(mostSimilarHashTag);
    }
  });

  return suitableHashTags;
}

export function extractEnglishWords(sentence) {
  // Regular expression to match English words and words with common special characters
  const englishWordRegex = /[a-zA-Z.'&,!?-]+/g;

  // Extract all English words from the sentence
  const englishWords = sentence.match(englishWordRegex);

  // Join the English words back into a sentence
  const result = englishWords ? englishWords.join(" ") : "";

  // If the result is empty, prompt the user to edit the sentence
  if (!result) {
    const editedSentence = prompt("Correct the word:", sentence);
    return editedSentence ? editedSentence : "";
  }

  return result;
}

export function priceFromList(product, qty) {
  var unit_price = product.unit_price;
  if (product.prices.length > 0) {
    const matchingPrice = product.prices.find(
      (price) => qty >= price.min && qty <= price.max
    );
    if (matchingPrice) {
      unit_price = matchingPrice.unit_price;
    }
  }
  return unit_price;
}

export function deepCopy(object) {
  const deepCopy = JSON.parse(JSON.stringify(object));
  return deepCopy;
}
