import React, { Component } from "react";
import { Badge, Button, Checkbox, Tag } from "rsuite";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import CloseIcon from "@rsuite/icons/Close";
import MemberIcon from "@rsuite/icons/Member";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import moment from "moment";
import constants, { shortenedText } from "../constants";
import Cookies from "js-cookie";
import { rateColors, rateTexts } from "./new-customer-form";
import { setFavCustomer } from "../Helpers/Utilities";
import CustomProgress from "../Widgets/CustomProgress";

class CustomerListItem extends Component {
  state = {};

  render() {
    var { customer, favs } = this.props;
    return (
      <div className="flex-row">
        {customer.image_path && (
          <img
            src={constants.imagePath + customer.image_path}
            style={{
              width: "100px",
              height: "50px",
              marginRight: "12px",
            }}
            data-action="zoom"
          />
        )}

        <div
          style={{
            textAlign: "left",
          }}
        >
          <strong>
            {customer.shop_name} (#{customer.id}){" "}
            {customer.latest_sales_return != null && (
              <Badge
                color={
                  customer.latest_sales_return.replace_product
                    ? "cyan"
                    : "orange"
                }
                content="Returns!"
              />
            )}
          </strong>
          <br />
          <i>{customer.address}</i>
          <div className="flex-row">
            {customer.sales_latest && (
              <span>
                {customer.sales_latest.status == "delivery" ? (
                  <Tag size="sm" color="yellow">
                    Delivery -{" "}
                    {moment(customer.sales_latest.delivery_date).fromNow()}
                  </Tag>
                ) : (
                  <Tag size="sm" color="blue">
                    Sales - {moment(customer.sales_latest.created_at).fromNow()}{" "}
                    - ({customer.sales_latest.status})
                  </Tag>
                )}
              </span>
            )}
            {customer.followups_latest && (
              <Tag size="sm">
                {customer?.followups_latest?.mode == "Phone" ? (
                  <PhoneFillIcon />
                ) : (
                  <MemberIcon />
                )}
                &nbsp;
                {shortenedText(customer.followups_latest.remarks)}
                {" - "}
                {moment(customer.followups_latest.created_at).fromNow()}
              </Tag>
            )}
          </div>
          <Button appearance="link" onClick={this.props.selectCustomer}>
            View
          </Button>
          <Checkbox
            checked={favs.indexOf(customer.id) !== -1}
            onChange={(value) =>
              setFavCustomer(customer, (favs) => this.props.onFavUpdate(favs))
            }
          >
            {" "}
            <CheckRoundIcon /> Favorite
          </Checkbox>
          <Tag size="sm">@{customer.created_by_name}</Tag>
          <br />
          {customer.followups_latest && (
            <CustomProgress progress={customer.followups_latest.response} />
          )}
        </div>
        <span
          style={{
            position: "absolute",
            top: "5px",
            right: "3px",
            fontSize: "3px !important !important",
            backgroundColor: "#ededed",
            borderRadius: "3.5px",
            padding: "3px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "5px",
              height: "5px",
              borderRadius: "2.5px",
              backgroundColor: rateColors[customer.rating],
            }}
          ></span>{" "}
          &nbsp;
          {rateTexts[customer.rating]}
        </span>
      </div>
    );
  }
}

export default CustomerListItem;
