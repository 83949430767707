import React, { Component } from "react";
import {
  Panel,
  Stack,
  Button,
  Rate,
  Divider,
  Loader,
  Input,
  InputNumber,
  InputPicker,
  Modal,
  Nav,
} from "rsuite";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PageNextIcon from "@rsuite/icons/PageNext";

import constants, {
  alertError,
  alertInfo,
  getShopTypeLabel,
} from "../../../constants";
import {
  rateTexts,
  renderRatingCharacter,
  rateColors,
} from "../../../components/new-customer-form";
import SalesList from "./sales-list";
import SalesEdit from "./sales-edit";
import Cookies from "js-cookie";
import Compressor from "compressorjs";
import { GoogleMap, Marker } from "@react-google-maps/api";
import SingleMap from "../../../Modules/SingleMap";
import SalesReturn from "./sales-return";
import CustomerFollowup from "./customer-followup";
import { loadRoutes } from "../../../Loaders/GeneralLoder";
import Revisions from "../../../Modules/Revisions";

class CustomerView extends Component {
  state = {
    active: "info",
    data: {},
    originalData: {},
    salesMode: "list",
    sales: [],
    loading: false,
    selectedSales: {},
    edit: false,
    locationModal: false,
    users: [],
    routes: [],
  };

  componentDidMount = () => {
    setTimeout(() => {
      const data = this.props.data;
      this.setState({
        data,
        originalData: data,
        salesMode: "list",
        locationModal: false,
        edit: false,
      });
      //load sales data
      this.loadSales();
      this.loadUsers();
      loadRoutes((routes) => this.setState({ routes }));
    }, 200);
  };

  onSelectTab = (active) => {
    this.setState({ active });
  };

  setSalesMode = (salesMode) => {
    if (salesMode == "list") {
      this.loadSales();
    }
    this.setState({ salesMode });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  loadSales = () => {
    this.setLoading();
    fetch(constants.url + `customers/${this.props.data.id}/sales`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ sales: data.data });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  loadUsers = () => {
    fetch(constants.url + "users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ users: data.data });
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.error(error);
      });
  };

  setSelectedSales = (selectedSales) => {
    this.setState({ selectedSales });
    this.setSalesMode("edit");
  };

  sendWelcomeMessage = () => {
    var { data } = this.state;
    const phoneNumber = data.phone; // Replace with the phone number you want to message
    const messageText = `Hello ${data.shop_name}, welcome to Stack Enterprises. You are successfully added to our customers database, Thank you.`;
    const url = `sms:${phoneNumber}&body=${messageText}`;
    window.open(url);
  };

  imageUpload = () => {
    window.imageUploadCallback = this.callBackTest;
    window.AndroidInterface.openCamera();
  };

  callBackTest = (test) => {
    alert(this.state.data.id + " - " + test);
  };

  handleUpload = async (event) => {
    this.setLoading();
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return alert("Please select a file to upload");
    }

    const formData = new FormData();

    const compressedImage = await new Promise((resolve) => {
      new Compressor(selectedFile, {
        quality: 0.1, // Adjust the quality to reduce file size
        success(result) {
          resolve(result);
        },
        error(error) {
          console.error(error.message);
          resolve(selectedFile); // Use the original file if compression fails
        },
      });
    });

    formData.append("image", compressedImage);

    try {
      const response = await fetch(
        constants.url + `customers/${this.state.data.id}/upload-image`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + Cookies.get("u-token"),
          },
        }
      );

      if (response.ok) {
        // alert("Image uploaded successfully");
        const responseData = await response.json();
        this.setState({
          data: responseData.data,
          originalData: responseData.data,
        });
        this.setLoading(false);
      } else {
        alert("Failed to upload image");
        this.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      alert("Error uploading image");
      this.setLoading(false);
    }
  };

  changeData = (item, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [item]: value,
      },
    }));
  };

  locationModal = (locationModal = true) => {
    this.setState({ locationModal });
  };

  saveData = () => {
    this.setLoading();
    const data = this.state.data;
    console.log("user update: " + constants.url + "customers/" + data.id);

    fetch(constants.url + "customers/" + data.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response_data) => {
        console.log(response_data);
        // this.viewCustomer();
        this.setState({
          data: response_data.data,
          originalData: response_data.data,
          edit: false,
        });
        this.setLoading(false);
        alertInfo("Updated!");
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        this.setState({ edit: false });
        alertError("Failed to update this customer.");
      });
  };

  render() {
    var { data, loading, edit } = this.state;
    var geo_location = JSON.parse(
      this.state.data.geo_location ?? this.props.data.geo_location
    );
    return (
      <div className="flex-column">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>
                <Button onClick={this.props.back} disabled={loading}>
                  <ArowBackIcon /> &nbsp;Back
                </Button>
                {this.props.selectedCustomerIndex != null &&
                  this.props.selectedCustomerIndex != 0 &&
                  this.props.selectPreviousCustomer && (
                    <Button
                      onClick={() => {
                        this.props.selectPreviousCustomer();
                        this.componentDidMount();
                      }}
                      disabled={loading}
                      appearance="link"
                      siz="xs"
                    >
                      <PagePreviousIcon /> &nbsp;Previous
                    </Button>
                  )}
                {this.props.selectedCustomerIndex != null &&
                  this.props.selectNextCustomer && (
                    <Button
                      onClick={() => {
                        this.props.selectNextCustomer();
                        this.componentDidMount();
                      }}
                      disabled={loading}
                      appearance="link"
                      siz="xs"
                    >
                      Next&nbsp; <PageNextIcon />
                    </Button>
                  )}
              </span>
              <strong>
                {data.shop_name} (#{data.id})
              </strong>
            </Stack>
          }
          bordered
        >
          <Nav
            appearance="subtle"
            justified
            activeKey={this.state.active}
            onSelect={this.onSelectTab}
            style={{ marginBottom: 10 }}
          >
            <Nav.Item eventKey="info">Info</Nav.Item>
            <Nav.Item eventKey="sales">Sales</Nav.Item>
            <Nav.Item eventKey="returns">Returns</Nav.Item>
            <Nav.Item eventKey="followup">Followup</Nav.Item>
          </Nav>

          {this.state.active == "info" && (
            <table className="table-style">
              <tr>
                <th>Customer ID</th> <th>#{data.id}</th>
              </tr>

              {this.props.sessionCheck.role == "Admin" && (
                <tr>
                  <td>Revisions</td>
                  <td>
                    <Revisions customerId={data.id} original={data} />
                  </td>
                </tr>
              )}
              <tr>
                <td>Route</td>
                <td>
                  <Stack>
                    <InputPicker
                      plaintext={!edit}
                      value={data.route_id}
                      data={this.state.routes?.map((item, index) => ({
                        label: item.route_name,
                        value: item.id,
                      }))}
                      onChange={(value) => this.changeData("route_id", value)}
                    />
                  </Stack>
                </td>
              </tr>
              <tr>
                <td>Shop Photo</td>
                <td>
                  {data.image_path && (
                    <img
                      src={constants.imagePath + data.image_path}
                      style={{
                        width: "120px",
                        height: "auto",
                        display: "block",
                      }}
                      data-action="zoom"
                    />
                  )}
                  {loading ? (
                    <Loader size="xs" content="Loading.." />
                  ) : (
                    edit && (
                      // <Button onClick={this.imageUpload}>Change Image</Button>
                      <input
                        type="file"
                        name="image"
                        onChange={this.handleUpload}
                      />
                    )
                  )}
                </td>
              </tr>
              <tr>
                <td>Shop Name</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.shop_name}
                    onChange={(value) => this.changeData("shop_name", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Contact Person</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.person_name}
                    onChange={(value) => this.changeData("person_name", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Contact Number</td>
                <td>
                  {!edit ? (
                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                  ) : (
                    <InputNumber
                      value={data.phone}
                      onChange={(value) => this.changeData("phone", value)}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.email}
                    onChange={(value) => this.changeData("email", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>GST</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.gst}
                    onChange={(value) => this.changeData("gst", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Shop type</td>
                <td>
                  <Stack>
                    <InputPicker
                      plaintext={!edit}
                      data={constants.shopTypes}
                      value={data.shop_type_id}
                      onChange={(value) =>
                        this.changeData("shop_type_id", value)
                      }
                    />
                  </Stack>
                </td>
              </tr>
              <tr>
                <td>Geo Location</td>
                <td>
                  <Button appearance="link" onClick={this.locationModal}>
                    {geo_location.lat}, {geo_location.lng}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>Postal PIN</td>
                <td>
                  <InputNumber
                    plaintext={!edit}
                    value={data.postal_pin}
                    onChange={(value) => this.changeData("postal_pin", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.address}
                    onChange={(value) => this.changeData("address", value)}
                  />
                  <br />
                  <a
                    href={`geo:${geo_location.lat},${geo_location.lng}`}
                    target="_blank"
                    onClick={(e) => {
                      if (
                        navigator.userAgent.match(/Android/i) ||
                        navigator.userAgent.match(/iPhone/i)
                      ) {
                        e.preventDefault();
                        window.location.href = `https://www.google.com/maps/search/?api=1&query=${geo_location.lat},${geo_location.lng}`;
                      }
                    }}
                  >
                    Open in Google Maps
                  </a>
                </td>
              </tr>
              <tr>
                <td>Rating</td>
                <td>
                  <div>
                    <Rate
                      renderCharacter={renderRatingCharacter}
                      value={data.rating}
                      readOnly={!edit}
                      onChange={(rating) => this.changeData("rating", rating)}
                      onChangeActive={(rating) =>
                        this.changeData("rating", rating)
                      }
                      cleanable={false}
                    />
                    <span>{rateTexts[data.rating]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Customer Radius</td>
                <td>
                  <InputNumber
                    min={0}
                    plaintext={
                      !edit || this.props.sessionCheck.role !== "Admin"
                    }
                    value={data?.radius}
                    onChange={(value) => this.changeData("radius", value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Assigned</td>
                <td>
                  <Stack>
                    <InputPicker
                      plaintext={
                        !edit || this.props.sessionCheck.role !== "Admin"
                      }
                      data={this.state.users.map((u) => ({
                        label: u.name,
                        value: u.id,
                      }))}
                      value={parseInt(data.created_by)}
                      onChange={(value) => this.changeData("created_by", value)}
                    />
                  </Stack>
                </td>
              </tr>
              <tr>
                <td>Remarks</td>
                <td>
                  <Input
                    plaintext={!edit}
                    value={data.remarks}
                    onChange={(value) => this.changeData("remarks", value)}
                  />
                </td>
              </tr>
              {this.props.sessionCheck.role == "Admin" ||
              this.props.sessionCheck.role == "Promoter" ? (
                <tr>
                  <td>Notify Customer</td>
                  <td>
                    <Divider vertical />
                    <a
                      href={`sms://${data.phone}/?body=Hello ${data.shop_name} REGID-${data.id}, welcome to Stack Enterprises. You are successfully added to our customers database, Thank you.`}
                    >
                      Send Welcome Message
                    </a>
                    {/* <Button
                  color="green"
                  appearance="primary"
                  onClick={this.sendWelcomeMessage}
                >
                  Send Welcome Message
                </Button> */}
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td>Actions</td>
                <td>
                  <Stack divider={<Divider vertical />}>
                    <Button
                      disabled={loading}
                      color="blue"
                      appearance="link"
                      onClick={() =>
                        edit ? this.saveData() : this.setState({ edit: true })
                      }
                    >
                      {edit ? "Save" : "Edit"}
                    </Button>
                    {edit && (
                      <Button
                        disabled={loading}
                        appearance="link"
                        onClick={() => {
                          this.setState({
                            data: this.state.originalData,
                            edit: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Stack>
                </td>
              </tr>
            </table>
          )}
        </Panel>
        <br />
        {this.state.active == "sales" && (
          <div>
            <Stack divider={<Divider vertical />}>
              <Divider vertical />
              {this.state.salesMode != "create" &&
                this.state.salesMode != "edit" && (
                  <Button
                    color="blue"
                    appearance="primary"
                    onClick={() => this.setSalesMode("create")}
                  >
                    New Sale
                  </Button>
                )}
              {this.state.salesMode != "list" && (
                <Button onClick={() => this.setSalesMode("list")}>
                  Back to list
                </Button>
              )}

              {this.state.salesMode == "list" && (
                <Button onClick={this.loadSales}>Refresh</Button>
              )}
            </Stack>
            <br />
            {this.state.salesMode == "list" &&
              (this.state.loading ? (
                <Loader size="sm" content="Loading sales list.." />
              ) : (
                <SalesList
                  setSalesMode={this.setSalesMode}
                  data={this.state.sales}
                  setSelectedSales={this.setSelectedSales}
                />
              ))}
            {this.state.salesMode == "create" && (
              <SalesEdit
                customer={data}
                setSalesMode={this.setSalesMode}
                sessionCheck={this.props.sessionCheck}
                users={this.state.users}
              />
            )}
            {this.state.salesMode == "edit" && (
              <SalesEdit
                customer={data}
                data={this.state.selectedSales}
                setSalesMode={this.setSalesMode}
                sessionCheck={this.props.sessionCheck}
                users={this.state.users}
              />
            )}
          </div>
        )}

        {this.state.active == "returns" && <SalesReturn customer={data} />}
        {this.state.active == "followup" && (
          <CustomerFollowup customer={data} />
        )}

        {/* Location modal */}
        <Modal
          open={this.state.locationModal}
          onClose={() => this.locationModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Geo Location - {data.shop_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data && (
              <SingleMap
                geo_location={geo_location}
                changeData={this.changeData}
                draggable={edit}
                currentLocation={this.props.currentLocation}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {edit && (
              <Button
                disabled={loading}
                onClick={this.saveData}
                appearance="primary"
              >
                Save
              </Button>
            )}

            {!edit && (
              <Button
                disabled={loading}
                onClick={() => this.setState({ edit: true })}
                appearance="primary"
                color="yellow"
              >
                Edit
              </Button>
            )}

            <Button
              disabled={loading}
              onClick={() => this.locationModal(false)}
              appearance="subtle"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CustomerView;
