import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Stack,
  Panel,
  ButtonGroup,
  Button,
  Loader,
  DateRangePicker,
  Divider,
  Badge,
} from "rsuite";
import ResponsiveNav from "@rsuite/responsive-nav";
import constants from "../../../../constants";

import UserInfo from "./user-info";
import UserSales from "./user-sales";
import UserCustomers from "./user-customers";
import UserLeads from "./user-leads";
import UserFollowups from "./user-followups";
import UserReturns from "./user-returns";
import UserLogs from "./user-logs";
import UserLocations from "./user-locations";
import CustomerView from "../customer-view";
import moment from "moment";
import UserPayments from "./user-payments";
import UserRoutes from "./user-routes";
import UserLeadFollowups from "./user-lead-followups";
import UserRevisions from "./user-revisions";

class UserView extends Component {
  state = {
    data: null,
    date: [new Date(), new Date()],
    active: "info",
    selectedCustomer: null,
  };

  back = () => {
    this.setState({ selectedCustomer: null });
    // this.loadUserData(this.state.date);
  };

  selectCustomer = (selectedCustomer) => {
    this.setState({ selectedCustomer });
  };

  componentDidMount = () => {
    this.loadUserData(this.state.date);
  };

  setActive = (active) => {
    if (!active) {
      return;
    }
    this.setState({ active });
  };

  loadUserData = (dateRange) => {
    this.setState({ data: null, date: dateRange });
    var userId = this.props.data.id;

    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

    console.log(dateRange);
    console.log(startDate);
    console.log(endDate);
    const url =
      constants.url +
      "users/" +
      this.props.data.id +
      `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
        endDate
      )}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data: data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deleteOne = (parent, indexToDelete) => {
    var data = this.state.data;

    if (
      data[parent] &&
      indexToDelete >= 0 &&
      indexToDelete < data[parent].length
    ) {
      data[parent].splice(indexToDelete, 1);
      // console.log("user_location deleted successfully");
      this.setState({ data });
    } else {
      console.log("Invalid index to delete");
    }
  };

  render() {
    var { data, active } = this.state;

    var salesValue = !data?.sales
      ? 0
      : data.sales
          .filter((item) => item.status.startsWith("paid"))
          .reduce(
            (total, { items }) =>
              total +
              items.reduce(
                (subTotal, item) => subTotal + item.unit_price * item.quantity,
                0
              ),
            0
          );

    var salesVolume = !data?.sales
      ? 0
      : data.sales
          .filter((item) => item.status.startsWith("paid"))
          .reduce(
            (total, { items }) =>
              total +
              items.reduce(
                (subTotal, item) => subTotal + parseInt(item.quantity),
                0
              ),
            0
          );

    if (this.state.selectedCustomer != null) {
      return (
        <CustomerView
          data={this.state.selectedCustomer}
          back={this.back}
          currentLocation={this.props.currentLocation}
          sessionCheck={this.props.sessionCheck}
        />
      );
    }

    return (
      <div>
        <Panel
          bordered
          header={
            <Stack justifyContent="space-between">
              <strong>{this.props.data.name}</strong>
              <DateRangePicker
                placeholder="Select Dates"
                onChange={this.loadUserData}
                placement="auto"
                value={this.state.date}
                size="sm"
                showOneCalendar
              />
              <ButtonGroup>
                {/* <Button
              onClick={() => this.setState({ createMode: true })}
              appearance="primary"
            >
              Add
            </Button> */}

                <Button
                  size="sm"
                  onClick={() => {
                    this.loadUserData(this.state.date);
                  }}
                >
                  Refresh
                </Button>
                <Button size="sm" onClick={this.props.back}>
                  Back
                </Button>
              </ButtonGroup>
            </Stack>
          }
        >
          <ResponsiveNav
            appearance="subtle"
            activeKey={active}
            onSelect={this.setActive}
            style={{ marginBottom: 5 }}
          >
            <ResponsiveNav.Item eventKey="info">Info</ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="sales">
              <Badge maxCount={9999} content={data?.sales?.length ?? 0}>
                Sales
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="payments">
              <Badge maxCount={9999} content={data?.payments?.length ?? 0}>
                Payments
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="customers">
              <Badge maxCount={9999} content={data?.customers?.length ?? 0}>
                Customers
              </Badge>
            </ResponsiveNav.Item>

            <ResponsiveNav.Item eventKey="followups">
              <Badge
                maxCount={9999}
                content={data?.customer_followups?.length ?? 0}
              >
                Customer Followups
              </Badge>
            </ResponsiveNav.Item>

            <ResponsiveNav.Item eventKey="leads">
              <Badge maxCount={9999} content={data?.lead_spots?.length ?? 0}>
                Leads
              </Badge>
            </ResponsiveNav.Item>

            <ResponsiveNav.Item eventKey="lead-followups-assigned">
              <Badge
                maxCount={9999}
                content={data?.assigned_lead_followups?.length ?? 0}
              >
                Followups (Assigned)
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="lead-followups-own">
              <Badge
                maxCount={9999}
                content={data?.own_lead_followups?.length ?? 0}
              >
                Followups (Own)
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="returns">
              <Badge maxCount={9999} content={data?.sales_returns?.length ?? 0}>
                Returns - Created
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="returns_replaced">
              <Badge
                maxCount={9999}
                content={data?.sales_returns_replaced?.length ?? 0}
              >
                Returns - Replaced
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="revisions">
              <Badge maxCount={9999} content={data?.revisions?.length ?? 0}>
                Revisions
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="logs">
              <Badge maxCount={9999} content={data?.user_logs?.length ?? 0}>
                Logs
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="locations">
              <Badge
                maxCount={9999}
                content={data?.user_locations?.length ?? 0}
              >
                Locate
              </Badge>
            </ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="routes">
              <Badge maxCount={9999} content={data?.routes?.length ?? 0}>
                Routes
              </Badge>
            </ResponsiveNav.Item>
          </ResponsiveNav>

          {data == null ? (
            <Loader content="Loading user data" />
          ) : (
            <div>
              {active == "info" && <UserInfo data={data} />}
              {active == "sales" && (
                <UserSales
                  data={data}
                  salesValue={salesValue}
                  salesVolume={salesVolume}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "customers" && (
                <UserCustomers
                  data={data}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "leads" && <UserLeads data={data} />}
              {active == "payments" && (
                <UserPayments
                  payments={data.payments}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "followups" && (
                <UserFollowups
                  data={data}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "returns" && (
                <UserReturns data={data} selectCustomer={this.selectCustomer} />
              )}
              {active == "returns_replaced" && (
                <UserReturns
                  data={data}
                  replaced={true}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "revisions" && (
                <UserRevisions
                  data={data}
                  selectCustomer={this.selectCustomer}
                />
              )}
              {active == "logs" && <UserLogs data={data} />}
              {active == "locations" && (
                <UserLocations
                  data={data}
                  salesValue={salesValue}
                  salesVolume={salesVolume}
                  refresh={() => this.loadUserData(this.state.date)}
                  deleteOne={this.deleteOne}
                  sessionCheck={this.props.sessionCheck}
                />
              )}
              {active == "routes" && (
                <UserRoutes
                  data={data}
                  sessionCheck={this.props.sessionCheck}
                />
              )}
              {active == "lead-followups-assigned" && (
                <UserLeadFollowups
                  followups={data.assigned_lead_followups}
                  sessionCheck={this.props.sessionCheck}
                  currentLocation={this.props.currentLocation}
                />
              )}
              {active == "lead-followups-own" && (
                <UserLeadFollowups
                  followups={data.own_lead_followups}
                  sessionCheck={this.props.sessionCheck}
                  currentLocation={this.props.currentLocation}
                />
              )}
            </div>
          )}
          <hr />
        </Panel>
      </div>
    );
  }
}

export default UserView;
