import React, { Component } from "react";
import { List, Tag, Badge } from "rsuite";
import moment from "moment";

class UserCustomers extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <List size="lg" hover>
          {data.customers.map((customer, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(customer)}
              >
                <strong># {customer.id}</strong> - {customer.shop_name} <br />{" "}
                <i>{customer.address}</i>
                <br />
                <Tag>Created: {moment(customer.created_at).fromNow()}</Tag>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserCustomers;
