import moment from "moment";
import React, { Component } from "react";
import { Stack, Tag, Rate, Button } from "rsuite";
import constants, { shortenedText } from "../constants";
import "./LeadListItem.css";
import { FaCommentAlt } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { FaPeopleCarry } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { MdEmojiEvents } from "react-icons/md";
import { MdEvent } from "react-icons/md";
import { MdOutlineDoneAll } from "react-icons/md";
import { getProgressColor } from "../Helpers/Utilities";
import CustomProgress from "../Widgets/CustomProgress";

class LeadListItem extends Component {
  state = {};
  render() {
    var { lead } = this.props;
    if (!lead) {
      return <i>LEAD #{lead?.lead_id} NOT FOUND</i>;
    }
    return (
      <div className="lead-item-container">
        {lead?.customer && <Tag>Existing</Tag>}
        {lead?.next_followup_date != null && (
          <Tag
            size="sm"
            color={
              moment(lead.next_followup_date).isBefore(moment())
                ? "red"
                : "green"
            }
            style={{ marginBottom: "10px" }}
          >
            Next Followup - {moment(lead.next_followup_date).fromNow()}
          </Tag>
        )}
        <div className="lead-item-head">
          <span className="lead-item-title" onClick={this.props.onClick}>
            {lead?.g_photo && (
              <img
                src={lead.g_photo}
                style={{
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                  borderRadius: "3px",
                  border: "0.5px solid #ededed",
                  display: "inline-block",
                }}
              />
            )}{" "}
            #{lead.id} - {lead.shop_name}
          </span>
          <Rate size="sm" value={lead.rate} readOnly />
        </div>
        <Stack>
          {lead.phone && (
            <Button appearance="ghost" color="blue" size="sm">
              <a href={`tel:${lead.phone}`}>
                <IoCall />
                Call
              </a>
            </Button>
          )}
          &nbsp;&nbsp;
          <Tag color={!lead.route_id && "red"} size="sm">
            {lead.route_name}
          </Tag>
          &nbsp;&nbsp;
          <Tag size="sm">@{lead.created_by_name}</Tag>
        </Stack>
        <br />
        <div>
          {JSON.parse(
            lead?.remarks?.startsWith("[")
              ? lead?.remarks
              : `["${lead?.remarks}"]`
          ).map((item) => (
            <span className="lead-item-tag">#{item}</span>
          ))}
        </div>

        <br />
        {lead.last_followup ? (
          <div className="lead-item-box">
            <div className="head">
              <span className="title">
                {lead.last_followup.kind == constants.followup_types[0] && (
                  <>
                    <span className="icon">
                      <IoCall />
                    </span>
                    Called &nbsp;&nbsp;
                  </>
                )}
                {lead.last_followup.kind == constants.followup_types[1] && (
                  <>
                    <span className="icon">
                      <FaPeopleCarry />
                    </span>
                    Visited &nbsp;&nbsp;
                  </>
                )}
                {lead.last_followup.kind == constants.followup_types[2] && (
                  <>
                    <span className="icon">
                      <MdMessage />
                    </span>
                    Texted &nbsp;&nbsp;
                  </>
                )}
                <span>{moment(lead.last_followup.created_at).fromNow()}</span>
              </span>

              <span>
                {lead.last_followup.event ? (
                  <>
                    {lead.last_followup.event_done ? (
                      <span className="done">Completed event</span>
                    ) : moment(lead.last_followup.event_date).isBefore(
                        moment()
                      ) ? (
                      <span className="past">Past event</span>
                    ) : (
                      <span className="upcoming">Upcoming event</span>
                    )}
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>

            <div className="remark">{lead.last_followup.remark}</div>
            <CustomProgress progress={lead.last_followup.response} />
            {lead.last_followup.event ? (
              <div className="event">
                <span>
                  <MdEmojiEvents /> &nbsp;&nbsp;{lead.last_followup.event_type}
                </span>
                <span>
                  <MdEvent /> &nbsp;&nbsp;
                  {moment(lead.last_followup.event_date).format("MMMM Do YYYY")}
                </span>
                {lead.last_followup.event_done_at ? (
                  <span>
                    {" "}
                    <MdOutlineDoneAll /> &nbsp;&nbsp;{" "}
                    <i>
                      {lead.last_followup.event_remark} |{" "}
                      {moment(lead.last_followup.event_done_at).fromNow()}
                    </i>
                  </span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="lead-item-empty-box">
            <FaCommentAlt />
            <span>No communications yet!</span>
          </div>
        )}

        {/* <Stack justifyContent="space-between">
          <div style={{ textAlign: "left" }}>
            <strong>
              {lead.shop_name}&nbsp;&nbsp;
              {lead.customer && <Tag>Existing</Tag>}
            </strong>
            <br />
            Route:{" "}
            <Tag color={!lead.route_id && "red"} size="xs">
              {lead.route_name}
            </Tag>{" "}
            <Tag size="xs">@{lead.created_by_name}</Tag>
            <br />
            <Rate size="sm" value={lead.rate} readOnly />
            <br />
            {JSON.parse(
              lead?.remarks?.startsWith("[")
                ? lead?.remarks
                : `["${lead?.remarks}"]`
            ).map((item) => (
              <Tag>{item}</Tag>
            ))}
            <br />
            {lead.last_followup ? (
              <Tag size="xs" color="green">
                {shortenedText(lead.last_followup.remark)}
                {moment(lead.last_followup.created_at).fromNow()}
              </Tag>
            ) : (
              <Tag size="xs">{moment(lead.next_visit).fromNow()}</Tag>
            )}
            {lead?.last_followup?.event ? (
              <>
                <Tag
                  color={lead?.last_followup?.event_done ? "green" : "orange"}
                  size="xs"
                >
                  Event ({lead.last_followup.event_type})
                  {moment(lead.last_followup.event_date).fromNow()}
                  {lead?.last_followup?.event_done
                    ? ` | Completed ${moment(
                        lead.last_followup.event_done_at
                      ).fromNow()}`
                    : ""}
                </Tag>
              </>
            ) : (
              ""
            )}
          </div>
        </Stack> */}
      </div>
    );
  }
}

export default LeadListItem;
