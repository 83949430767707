import React, { Component } from "react";
import { List, Tag, Badge } from "rsuite";
import moment from "moment";

class UserSales extends Component {
  state = {};
  render() {
    var { data } = this.props;
    return (
      <div>
        <strong>Sales Value: {this.props.salesValue}</strong>
        <br />
        <strong>Sales Volume: {this.props.salesVolume}</strong>
        <br />
        <br />
        <List size="lg" hover>
          {data.sales.map((sale, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(sale.customer)}
              >
                <strong>Invoice # {sale.id}</strong> - {sale.created_at} <br />{" "}
                <Tag>
                  {sale.items.length} Items |{" "}
                  {sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Pcs |{" "}
                  {sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      parseFloat(currentValue?.unit_price) *
                        parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Rupees
                </Tag>
                <br />
                <strong>
                  {sale.customer_id} - {sale.customer_name}{" "}
                  {moment(sale.created_at).isSame(
                    sale.customer_created,
                    "day"
                  ) ? (
                    <Tag size="xs" color="green">
                      &nbsp;New Customer&nbsp;
                    </Tag>
                  ) : (
                    <Tag size="xs" color="orange">
                      &nbsp;Old Customer&nbsp;
                    </Tag>
                  )}
                </strong>
                <br />
                <strong>Status: {sale.status}</strong>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserSales;
