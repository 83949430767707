import React, { Component } from "react";
import { Button, Drawer } from "rsuite";

class SalesQtyList extends Component {
  state = {
    list: [], // Updated list for aggregated quantities
    drawer: false,
  };

  drawer = (drawer = true) => {
    this.setState({ drawer, list: [] });
    if (drawer) {
      this.prepare();
    }
  };

  prepare = () => {
    // Get customers from props
    const customers = this.props.customers;

    // Function to aggregate sale item quantities
    const aggregateSaleItemQuantities = () => {
      // Temporary object to store quantities and product names
      const tempQuantities = {};

      // Iterate through customers
      if (customers) {
        console.log(customers);
        customers.forEach(({ customer }) => {
          // Check if the customer has sales_latest
          if (customer?.sales_latest) {
            // Iterate through items in sales_latest
            customer.sales_latest.items.forEach((item) => {
              const { product_id, quantity, product } = item;

              // If product_id is not in tempQuantities, initialize it with quantity and product name
              if (!tempQuantities[product_id]) {
                tempQuantities[product_id] = {
                  quantity,
                  product_name: product.name, // Assuming the product model has a 'name' attribute
                };
              } else {
                // If product_id already exists, add quantity to the existing value
                tempQuantities[product_id].quantity += quantity;
              }
            });
          }
        });
      }

      // Iterate through sales
      if (this.props.sales) {
        console.log(this.props.sales);
        this.props.sales.forEach((sale) => {
          // Check if the customer has sales_latest

          // Iterate through items in sales_latest
          sale.items.forEach((item) => {
            const { product_id, quantity, product } = item;

            // If product_id is not in tempQuantities, initialize it with quantity and product name
            if (!tempQuantities[product_id]) {
              tempQuantities[product_id] = {
                quantity,
                product_name: product.name, // Assuming the product model has a 'name' attribute
              };
            } else {
              // If product_id already exists, add quantity to the existing value
              tempQuantities[product_id].quantity += quantity;
            }
          });
        });
      }

      // Iterate through payments
      if (this.props.payments) {
        this.props.payments.forEach(({ sale }) => {
          // Iterate through items in sales_latest
          sale.items.forEach((item) => {
            const { product_id, quantity, product } = item;

            // If product_id is not in tempQuantities, initialize it with quantity and product name
            if (!tempQuantities[product_id]) {
              tempQuantities[product_id] = {
                quantity,
                product_name: product.name, // Assuming the product model has a 'name' attribute
              };
            } else {
              // If product_id already exists, add quantity to the existing value
              tempQuantities[product_id].quantity += quantity;
            }
          });
        });
      }

      // Iterate through returns
      if (this.props.returns) {
        console.log(this.props.returns);
        this.props.returns.forEach((returnItem) => {
          // Access the nested return object
          const { product_id, quantity, product } = returnItem.return;

          // If product_id is not in tempQuantities, initialize it with quantity and product name
          if (!tempQuantities[product_id]) {
            tempQuantities[product_id] = {
              quantity,
              product_name: product.name, // Assuming the product model has a 'name' attribute
            };
          } else {
            // If product_id already exists, add quantity to the existing value
            tempQuantities[product_id].quantity += quantity;
          }
        });
      }

      // Convert the temporary object to an array
      const quantitiesArray = Object.values(tempQuantities);

      // Update the state with the aggregated quantities
      this.setState({ list: quantitiesArray });
    };

    // Call the function to aggregate quantities
    aggregateSaleItemQuantities();
  };

  render() {
    return (
      <>
        <Button size="sm" onClick={this.drawer} style={{ margin: "10px" }}>
          {this.props.label ?? "Qty"}
        </Button>
        <Drawer
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
          size="sm"
          placement="bottom"
          style={{ maxWidth: "100vw !important" }}
        >
          <Drawer.Header>
            <Drawer.Title>
              {this.props.label ?? "Quantity Calculator"}
            </Drawer.Title>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <ul>
              {this.state.list.map((item) => (
                <li key={item.product_id}>
                  <strong>{item.product_name}:</strong> Quantity:{" "}
                  {item.quantity}
                </li>
              ))}
            </ul>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default SalesQtyList;
