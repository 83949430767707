import React, { Component } from "react";
import { Divider, Grid, Row, Col, Button, Progress } from "rsuite";
import { loadStats } from "../../Loaders/GeneralLoder";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class Dashboard extends Component {
  state = {
    stats: null,
  };

  componentDidMount = () => {
    loadStats((stats) => this.setState({ stats }));
  };

  transformData = (events, eventsDone, customers) => {
    // Create a map to hold aggregated data by day
    const dataMap = new Map();

    const aggregateData = (data, key) => {
      data.forEach((item) => {
        if (!dataMap.has(item.day)) {
          dataMap.set(item.day, { day: item.day });
        }
        dataMap.get(item.day)[key] = item.count;
      });
    };

    aggregateData(events, "Events");
    aggregateData(eventsDone, "Events Done");
    aggregateData(customers, "Customers");

    return Array.from(dataMap.values());
  };

  render() {
    const { stats } = this.state;
    const combinedData = stats
      ? this.transformData(stats.events, stats.eventsDone, stats.customers)
      : [];

    var customers = stats?.customers?.reduce(
      (total, item) => total + item.count,
      0
    );

    var minCustomer =
      this.props.sessionCheck?.active_employment?.employment_type
        ?.min_customers;
    var customerPercentage = (100 / minCustomer) * customers;

    return (
      <div>
        {minCustomer > 0 && (
          <div>
            <strong>Incetive Scale:</strong>
            <Progress.Line
              percent={customerPercentage.toFixed(2)}
              strokeColor={
                customerPercentage < 50
                  ? "red"
                  : customerPercentage >= 100
                  ? "green"
                  : "blue"
              }
              status="active"
            />
          </div>
        )}
        <h1>Full Month Stats</h1>
        {stats && (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Events"
                name="Events"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="Events Done"
                name="Events Done"
                stroke="#82ca9d"
              />
              <Line
                type="monotone"
                dataKey="Customers"
                name="Customers"
                stroke="#ffc658"
              />
            </LineChart>
          </ResponsiveContainer>
        )}{" "}
        {/* Render the Line component only if stats is available */}
        <Divider>Other Stats</Divider>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={12}>
              <Button block appearance="ghost" color="red" size="lg">
                Customers - {customers}
              </Button>
            </Col>
            <Col xs={12}>
              <Button block appearance="ghost" color="yellow" size="lg">
                Visits -{" "}
                {stats?.events?.reduce((total, item) => total + item.count, 0)}
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="show-grid">
            <Col xs={12}>
              <Button block appearance="ghost" color="green" size="lg">
                Payments -{" "}
                {stats?.payments?.reduce(
                  (total, item) => total + item.total_amount,
                  0
                )}
              </Button>
            </Col>
            <Col xs={12}>
              <Button block appearance="ghost" color="green" size="lg">
                Volume - 0
              </Button>
            </Col>
          </Row>
          <br />
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
