import React, { Component } from "react";
import {
  Drawer,
  List,
  Nav,
  Button,
  Tag,
  InputPicker,
  SelectPicker,
  Loader,
  Message,
  ButtonToolbar,
  Stack,
} from "rsuite";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import { dummyCallLogs } from "../Helpers/dummy";
import moment from "moment";
import constants, { alertError } from "../constants";
import Cookies from "js-cookie";
import { loadHashTags } from "../Loaders/GeneralLoder";
import HomeLeadView from "../Pages/sub-pages/mobile/home-lead-view";
import CustomerView from "../Pages/sub-pages/mobile/customer-view";
import ReloadIcon from "@rsuite/icons/Reload";

export const callLogTypes = [
  "",
  "Incoming Call",
  "Outgoing Call",
  "Missed Call",
  "Voicemail",
  "Rejected Call",
  "Blocked Call",
  "Answered Externally",
];

class CallLogs extends Component {
  state = {
    drawer: false,
    data: [],
    selectedCustomer: null,
    selectedLead: null,
    logLimit: 10,
    loading: false,
  };

  drawer = (drawer) => this.setState({ drawer });
  setLoading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    // window.callLogs = dummyCallLogs;
    this.searchLogs(false);
  };

  searchLogs = (refetch = true) => {
    this.setState({ data: [] });
    this.setLoading();
    if (refetch) {
      window.reFetchCallLogs();
    }

    setTimeout(() => {
      if (!window?.callLogs) {
        return;
      }
      fetch(constants.url + "search-numbers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify({
          logs: window?.callLogs?.slice(0, this.state.logLimit),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ data });
          this.setLoading(false);
        })
        .catch((error) => {
          alertError("Unable to search for logs..");
          console.error(error);
          this.setLoading(false);
        });
    }, 2000);
  };

  render() {
    var callLogs = window.callLogs;
    if (callLogs == null) {
      return "";
    }

    var { selectedCustomer, selectedLead } = this.state;
    return (
      <>
        <Nav.Item icon={<PhoneFillIcon />} onClick={this.drawer}></Nav.Item>

        <Drawer
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
          placement="bottom"
          size="lg"
        >
          <Drawer.Header>
            <Drawer.Title>Call Logs.</Drawer.Title>
            <Drawer.Actions>
              <Stack>
                <SelectPicker
                  size="sm"
                  style={{ width: 224 }}
                  searchable={false}
                  value={this.state.logLimit}
                  onChange={(logLimit) =>
                    this.setState({ logLimit }, this.searchLogs)
                  }
                  data={[
                    { label: "Last 10 Call Logs", value: 10 },
                    { label: "Last 25 Call Logs", value: 25 },
                    { label: "Last 50 Call Logs", value: 50 },
                    { label: "Last 100 Call Logs", value: 100 },
                  ]}
                  disabled={this.state.loading}
                />

                <Button onClick={this.searchLogs}>
                  <ReloadIcon />
                </Button>
              </Stack>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {this.state.loading && <Loader content="loading call logs.." />}
            <List hover>
              {this.state.data.map((log) => (
                <List.Item>
                  <i>{callLogTypes[log.type]}</i>
                  <br />
                  <strong>{log.number}</strong>
                  <Tag>
                    {moment(log.date).fromNow()} | Duration: {log.duration}{" "}
                    seconds
                  </Tag>
                  <br />
                  {log.customer ? (
                    <Message
                      showIcon
                      type="info"
                      header={`Customer: ${log.customer.shop_name}`}
                    >
                      {log?.customer?.followups_latest && (
                        <p>
                          {log?.customer?.followups_latest?.remarks} <br />{" "}
                          {moment(
                            log?.customer?.followups_latest?.created_at
                          ).fromNow()}
                        </p>
                      )}
                      <hr />
                      <ButtonToolbar>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.setState({ selectedCustomer: log.customer })
                          }
                        >
                          View
                        </Button>
                      </ButtonToolbar>
                    </Message>
                  ) : log.lead ? (
                    <Message
                      showIcon
                      type="info"
                      header={`Lead: ${log.lead.shop_name}`}
                    >
                      {log?.lead?.last_followup && (
                        <p>
                          {log?.lead?.last_followup?.remark} <br />{" "}
                          {moment(
                            log?.lead?.last_followup?.created_at
                          ).fromNow()}
                        </p>
                      )}
                      <hr />
                      <ButtonToolbar>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.setState({ selectedLead: log.lead })
                          }
                        >
                          View
                        </Button>
                      </ButtonToolbar>
                    </Message>
                  ) : (
                    ""
                  )}
                </List.Item>
              ))}
            </List>
          </Drawer.Body>
        </Drawer>

        {/* Lead & Custoemr View Drawer */}
        {selectedCustomer != null || selectedLead != null ? (
          <Drawer
            placement="bottom"
            size="lg"
            open={selectedCustomer != null || selectedLead != null}
            onClose={() =>
              this.setState({ selectedCustomer: null, selectedLead: null })
            }
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={this.state.selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : selectedLead ? (
                <HomeLeadView
                  lead={selectedLead}
                  back={() => this.setState({ selectedLead: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                  setView={(selectedLead) => this.setState({ selectedLead })}
                  loadLeads={() => {}}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default CallLogs;
