import Cookies from "js-cookie";
import React, { Component } from "react";
import Geocode from "react-geocode";
import {
  Button,
  Nav,
  Navbar,
  Stack,
  Input,
  InputNumber,
  InputPicker,
  Rate,
  Loader,
  Tag,
  Message,
  Divider,
  TagPicker,
} from "rsuite";
import constants, { alertError, alertInfo } from "../../../constants";
import HomeLeadFollowup from "../../home-leads-followup";
import CustomerView from "./customer-view";
import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  MarkerF,
} from "@react-google-maps/api";
import { checkRoute, getRouteName } from "../../../Helpers/RouteHelper";
import moment from "moment";
import { createHashTag } from "../../../Loaders/GeneralSaver";
import { loadHashTags, loadRoutes } from "../../../Loaders/GeneralLoder";
import Revisions from "../../../Modules/Revisions";

Geocode.setApiKey(constants.map_api);
Geocode.setLanguage("en");

class HomeLeadView extends Component {
  state = {
    activeNav: "details",
    editing: false,
    locationAddress: null,
    postal_pin: null,
    loading: false,
    lastLoadId: null,
    locationEdit: false,
    routes: [],
    hashTags: [],
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  componentDidMount = () => {
    this.loadAddress();
    loadRoutes((routes) => this.setState({ routes }));
    loadHashTags((hashTags) => this.setState({ hashTags }));
  };

  componentDidUpdate = () => {
    if (this.state.lastLoadId !== this.props?.lead?.id) {
      this.setState({ lastLoadId: this.props.lead.id }, this.loadAddress);
    }
  };

  loadAddress = () => {
    var { lead } = this.props;
    var geolocation = JSON.parse(lead.geolocation);
    this.setLoading();
    Geocode.fromLatLng(geolocation?.lat, geolocation?.lng).then(
      (response) => {
        const locationAddress = response.results[0].formatted_address;
        var postal_pin = response.results[0]?.address_components.find(
          (component) => component.types.includes("postal_code")
        )?.long_name;
        this.setState({ locationAddress, postal_pin });
        this.setLoading(false);
      },
      (error) => {
        console.error(error);
        this.setLoading(false);
      }
    );
  };

  createCustomer = () => {
    const conf = window.confirm(
      "Are you sure to create customer from this lead?"
    );
    if (!conf) {
      return;
    }

    this.setLoading();

    // Avoid circular references by creating a clean object for the data
    const data = {
      address: this.state.locationAddress,
      postal_pin: this.state?.postal_pin?.slice(-6) ?? "",
    };

    fetch(constants.url + `lead_spots/${this.props.lead.id}/create_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        const dummyLeadAfterDeleted = { ...this.props.lead };
        dummyLeadAfterDeleted.customer = data.customer;
        this.props.setView(dummyLeadAfterDeleted);
        this.setState({ activeNav: "customer" });
        this.setLoading(false);
        alertInfo("Customer Created");
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        alertError("Failed to create customer");
      });
  };

  deleteLead = () => {
    const conf = window.confirm("DELETE this lead?");
    if (!conf) {
      return;
    }

    this.setLoading();

    fetch(constants.url + `lead_spots/${this.props.lead.id}/del`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        this.props.remove();
        alertInfo("Deleted Lead");
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        alertError("Failed to Delete Lead");
      });
  };

  updateLead = () => {
    this.setLoading();
    var { view, edit, dummy, useStreetView } = this.state;
    var data = this.props.lead;

    var actual_route_id = checkRoute(
      typeof data.geolocation === "string"
        ? JSON.parse(data.geolocation)
        : data.geolocation,
      this.state.routes
    )?.id;

    data.route_id = !data.route_id ? actual_route_id : data.route_id;

    fetch(constants.url + "lead_spots/" + data.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        if (data.success == false) {
          alertError(data.message);
        } else {
          this.props.setView(data.data);
          alertInfo(`Updated Lead`);
          this.props.loadLeads();
        }
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        alertError("Failed to update lead");
      });
  };

  changeVal = (name, value) => {
    var { lead } = this.props;
    lead[name] = value;
    this.props.setView(lead);
  };

  render() {
    var { lead, back, next, previous } = this.props;
    var {
      activeNav,
      editing,
      locationAddress,
      postal_pin,
      loading,
      locationEdit,
    } = this.state;

    var actual_route = checkRoute(
      JSON.parse(this.props.lead.geolocation),
      this.state.routes
    );

    return (
      <div>
        <Stack divider=" | ">
          <Button size="xs" appearance="ghost" onClick={back}>
            Back
          </Button>
          <Button size="xs" appearance="ghost" onClick={previous}>
            &lt;&lt;Previous Lead
          </Button>
          <Button size="xs" appearance="ghost" onClick={next}>
            Next Lead&gt;&gt;
          </Button>
        </Stack>
        <br />
        <Navbar appearance="subtle">
          <Nav
            activeKey={activeNav}
            onSelect={(activeNav) => this.setState({ activeNav })}
            justified
            defaultActiveKey="details"
            appearance="subtle"
          >
            <Nav.Item eventKey="details">Details</Nav.Item>
            <Nav.Item eventKey="map">Map</Nav.Item>
            <Nav.Item eventKey="followup">Followup</Nav.Item>
            {lead?.customer && (
              <Nav.Item eventKey="customer">Customer</Nav.Item>
            )}
          </Nav>
          <Nav pullRight>
            {activeNav == "details" &&
              (this.state.editing ? (
                <Nav.Item
                  onClick={() => {
                    this.setState({ editing: false });
                    this.updateLead(true);
                  }}
                >
                  Save
                </Nav.Item>
              ) : (
                <Nav.Item onClick={() => this.setState({ editing: true })}>
                  Edit
                </Nav.Item>
              ))}
          </Nav>
        </Navbar>

        {activeNav == "details" && (
          <div>
            {this.props.sessionCheck.role == "Admin" && (
              <Revisions leadId={lead.id} original={lead} />
            )}
            <br />
            {lead.g_photo && (
              <img
                src={lead.g_photo}
                style={{
                  width: "auto",
                  height: "80px",
                  marginLeft: "5px",
                }}
                data-action="zoom"
              />
            )}
            <br />
            {loading ? (
              <Loader content="loading location address.." />
            ) : (
              <i>
                {locationAddress} - {postal_pin}
              </i>
            )}
            <br />

            <p>
              <strong>
                Route:{" "}
                {lead.route_id ? (
                  lead.route_name
                ) : (
                  <Tag color="red" size="xs">
                    No Route
                  </Tag>
                )}
              </strong>
            </p>

            {actual_route?.id != lead.route_id && (
              <Message type="warning">
                Route change required,{" "}
                <strong>{actual_route?.route_name}</strong> is detected (you may
                simply click edit and save to apply)
              </Message>
            )}

            <strong>Lead ID: #{lead.id}</strong>
            <br />
            {editing ? (
              <Input
                placeholder="Shop name"
                block
                onChange={(value) => {
                  this.changeVal("shop_name", value);
                }}
                value={lead.shop_name}
                plaintext={!editing}
              />
            ) : (
              <h3>{lead.shop_name}</h3>
            )}

            <br />
            <Tag>Created by @{lead.created_by_name}</Tag>

            <br />

            {editing ? (
              <InputNumber
                placeholder="Contact number"
                block
                onChange={(value) => {
                  this.changeVal("phone", value);
                }}
                value={lead.phone}
                plaintext={!editing}
              />
            ) : (
              <a href={`tel:${lead.phone}`}>{lead.phone}</a>
            )}

            <br />

            <Input
              placeholder="Notes"
              block
              onChange={(value) => {
                this.changeVal("notes", value);
              }}
              value={lead.notes}
              plaintext={!editing}
            />

            <br />
            <strong>Remarks #tags</strong>
            {!lead?.remarks?.startsWith("[") && <Tag>{lead?.remarks}</Tag>}
            <TagPicker
              creatable
              data={this.state.hashTags?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onCreate={(hashTags) => {
                createHashTag(hashTags[hashTags.length - 1], (newhashTags) =>
                  this.setState({ hashTags: newhashTags })
                );
              }}
              block
              placeholder="Remarks or #tags"
              onChange={(value) => {
                console.log(value);
                this.changeVal("remarks", JSON.stringify(value));
              }}
              value={JSON.parse(
                lead?.remarks?.startsWith("[")
                  ? lead?.remarks
                  : lead?.remarks?.length > 0 && lead?.remarks != null
                  ? `["${lead?.remarks}"]`
                  : "[]"
              )}
              plaintext={!editing}
            />
            <br />

            <Rate
              onChange={(value) => {
                this.changeVal("rate", value);
              }}
              onChangeActive={(value) => {
                this.changeVal("rate", value);
              }}
              value={lead.rate}
              readOnly={!editing}
            />
            <br />
            {this.state.editing && (
              <Button
                block
                appearance="primary"
                color="blue"
                onClick={() => {
                  this.setState({ editing: false });
                  this.updateLead(true);
                }}
                disabled={loading}
              >
                Save
              </Button>
            )}
            <br />
            {lead?.last_followup && (
              <span>
                <Divider>
                  {lead?.last_followup?.event ? (
                    <span>
                      Recent Event -{" "}
                      {lead.last_followup.event_done ? "Completed" : "Pending"}
                    </span>
                  ) : (
                    "Last Followup"
                  )}
                </Divider>
                <Message
                  onClick={() => this.setState({ activeNav: "followup" })}
                >
                  {lead.last_followup.remark} <br />
                  <i>
                    {lead.last_followup.kind} -{" "}
                    {moment(
                      lead.last_followup.event_date ??
                        lead.last_followup.created_at
                    ).fromNow()}
                  </i>
                </Message>
              </span>
            )}

            <br />

            <Button
              block
              appearance="primary"
              color="green"
              onClick={() =>
                (window.location.href = `https://www.google.com/maps/search/?api=1&query=${
                  JSON.parse(lead.geolocation).lat
                },${JSON.parse(lead.geolocation).lng}`)
              }
            >
              Open in Google Map
            </Button>
            <br />

            {!lead.customer && lead.phone && (
              <Button
                block
                onClick={this.createCustomer}
                disabled={loading}
                appearance="primary"
              >
                CREATE CUSTOMER
              </Button>
            )}
            <br />
            {!lead.customer &&
              !lead.phone &&
              this.props.sessionCheck.role == "Admin" && (
                <Button
                  block
                  onClick={this.createCustomer}
                  disabled={loading}
                  appearance="link"
                  color="orange"
                >
                  Create Customer Without Phone
                </Button>
              )}
            <br />
            {this.props.sessionCheck.role == "Admin" && (
              <Button
                block
                onClick={this.deleteLead}
                disabled={loading}
                appearance="ghost"
                color="red"
              >
                Delete Lead !!!
              </Button>
            )}
          </div>
        )}

        {activeNav == "followup" && (
          <HomeLeadFollowup
            lead={lead}
            refresh={this.props.loadLeads}
            sessionCheck={this.props.sessionCheck}
          />
        )}

        {activeNav == "customer" && lead?.customer != null && (
          <CustomerView
            data={lead.customer}
            back={{}}
            currentLocation={this.props.currentLocation}
            sessionCheck={this.props.sessionCheck}
            //   selectedCustomerIndex={this.state.selectedCustomerIndex}
            //   selectNextCustomer={this.selectNextCustomer}
            //   selectPreviousCustomer={this.selectPreviousCustomer}
          />
        )}

        {activeNav == "map" && (
          <div>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "460px",
              }}
              center={JSON.parse(lead?.geolocation)}
              zoom={18}
              onLoad={(mapObject) => this.setState({ mapObject })}
              onUnmount={() => this.setState({ mapObject: null })}
              mapTypeId="roadmap"
            >
              <MarkerF
                position={JSON.parse(lead.geolocation)}
                icon={{
                  url: constants.map_marker_blue,
                  scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                }}
                label={{
                  text: lead.shop_name,
                  fontSize: "10",
                }}
                draggable={locationEdit}
                onDragEnd={(event) => {
                  const newLocation = event.latLng;
                  this.changeVal("geolocation", JSON.stringify(newLocation));
                }}
                onDblClick={() =>
                  (window.location.href = `https://www.google.com/maps/search/?api=1&query=${
                    JSON.parse(lead.geolocation).lat
                  },${JSON.parse(lead.geolocation).lng}`)
                }
              />

              <Marker
                position={this.props.currentLocation}
                label={{
                  text: "You",
                  fontSize: "10",
                }}
              />
            </GoogleMap>
            <br />
            <Button
              block
              appearance="primary"
              onClick={() => {
                if (locationEdit) {
                  this.updateLead();
                }
                this.setState({ locationEdit: !locationEdit });
              }}
            >
              {locationEdit ? "Save Location" : "Edit Location"}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default HomeLeadView;
