import React, { Component } from "react";
import {
  Button,
  CheckPicker,
  InputGroup,
  Input,
  Modal,
  Divider,
  Toggle,
  Drawer,
  DatePicker,
  Nav,
  Badge,
  Calendar,
  Tag,
  List,
} from "rsuite";
import {
  loadDeliveryData,
  loadRoutes,
  loadVisitData,
} from "../Loaders/GeneralLoder";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import FunnelIcon from "@rsuite/icons/Funnel";
import ListIcon from "@rsuite/icons/List";
import ExploreIcon from "@rsuite/icons/Explore";
import ReloadIcon from "@rsuite/icons/Reload";
import { MdOutlineGraphicEq } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";

import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, { alertInfoSilent } from "../constants";
import CustomerView from "./sub-pages/mobile/customer-view";
import HomeLeadView from "./sub-pages/mobile/home-lead-view";
import moment from "moment";
import CustomLoader from "../Widgets/CustomLoader";
import LeadListItem from "../components/LeadListItem";
import { deepCopy, getFavCustomers } from "../Helpers/Utilities";
import SalesQtyList from "../Modules/SalesQtyList";
import CustomerListItem from "../components/CustomerListItem";

class Deliveries extends Component {
  state = {
    routes: [],
    selectedRoutes: JSON.parse(Cookies.get("selectedRoutes") ?? "[]"),
    loading: false,
    followMe: false,
    map: null,
    filterGoogleMapPlaces: false,
    filterToggleReturns: true,

    selectedCustomer: null,
    mode: "dash",

    deliveryDates: [],
    filteredData: null,
  };

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
    if (this.state.selectedRoutes?.length > 0) {
      this.loadData();
    }
  };

  componentDidUpdate = () => {
    //stick map position to current location..
    if (this.state.map && this.state.followMe) {
      this.state.map.setCenter(this.props.currentLocation);
    }
  };

  loadData = () => {
    this.setState({ loading: true });
    loadDeliveryData(this.state.selectedRoutes, (data) => {
      var deliveryDates = data?.delivery?.map(
        (del) => new Date(del?.delivery_date)
      );

      this.setState({ data, deliveryDates, loading: false }, this.filteredData);
    });
  };

  filteredData = () => {
    var { data, filterDeliveryDate } = this.state;

    if (!data) {
      return null;
    }

    var newData = deepCopy(data);

    if (filterDeliveryDate) {
      newData.delivery = newData.delivery.filter((sale) =>
        moment(sale.delivery_date).isSame(moment(filterDeliveryDate), "day")
      );
    }

    this.setState({ filteredData: newData });

    return newData;
  };

  render() {
    var {
      routes,
      selectedRoutes,
      loading,
      followMe,
      selectedCustomer,
      mode,
      deliveryDates,
      filteredData,
    } = this.state;

    const data = filteredData;
    return (
      <div>
        <CustomLoader full text="Loading deliveries.." loading={loading} />
        <InputGroup>
          <SalesQtyList sales={data?.delivery} />

          <CheckPicker
            size="sm"
            label="Routes"
            data={routes.map((route) => ({
              label: route.route_name,
              value: route.id,
            }))}
            loading={loading}
            value={selectedRoutes}
            onChange={(selectedRoutes) => {
              this.setState({ selectedRoutes });
              Cookies.set("selectedRoutes", JSON.stringify(selectedRoutes));
            }}
          />
          <Button disabled={loading} onClick={this.loadData} size="md">
            <ReloadIcon />
          </Button>
          <Button
            disabled={loading}
            size="xs"
            onClick={() => this.setState({ filterModal: true })}
          >
            <FunnelIcon />
          </Button>
          <Button
            disabled={loading}
            onClick={() => this.setState({ followMe: !followMe })}
            appearance={followMe ? "primary" : "default"}
            color="green"
            size="xs"
          >
            <ExploreIcon />
          </Button>
        </InputGroup>

        {/* Mode Nav */}
        <Nav
          appearance="subtle"
          justified
          defaultActiveKey="dash"
          activeKey={mode}
          onSelect={(mode) => this.setState({ mode })}
        >
          <Nav.Item eventKey="dash">Calendar</Nav.Item>
          <Nav.Item eventKey="list">List</Nav.Item>
          <Nav.Item eventKey="map">Map</Nav.Item>
        </Nav>

        {/* Dashboard mode */}
        {mode === "dash" && (
          <div>
            <Calendar
              //   value={new Date()} // Current date
              defaultValue={new Date()} // Default date
              onSelect={(date) =>
                this.setState(
                  {
                    filterDeliveryDate: date,
                    mode: "list",
                  },
                  this.filteredData
                )
              }
              // disabledDate={(date) =>
              //   date.getDay() === 0 || date.getDay() === 6
              // } // Disable weekends
              renderCell={(date, dateState) => {
                const deliveriesOnDate =
                  deliveryDates?.filter(
                    (deliveryDate) =>
                      deliveryDate.getDate() === date.getDate() &&
                      deliveryDate.getMonth() === date.getMonth() &&
                      deliveryDate.getFullYear() === date.getFullYear()
                  ).length || 0;

                return (
                  <div>
                    <br />
                    <Tag color={deliveriesOnDate ? "blue" : ""}>
                      {deliveriesOnDate > 0 ? deliveriesOnDate : "0"}
                    </Tag>
                  </div>
                );
              }}
            />
          </div>
        )}

        {/* Map Mode */}
        {mode == "map" && (
          <>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "76vh",
              }}
              zoom={10}
              onLoad={(map) => {
                this.setState({ map });
                map.setCenter(this.props.currentLocation);
              }}
              onUnmount={() => this.setState({ map: null })}
              mapTypeId="roadmap"
              options={{
                styles: !this.state.filterGoogleMapPlaces
                  ? [
                      {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }], // Hides all points of interest (places marker)
                      },
                    ]
                  : [], // Empty styles array means no styles are applied
              }}
            >
              {selectedRoutes.map((selected) =>
                routes.map(
                  (route) =>
                    route.id == selected && (
                      <PolygonF
                        options={{
                          paths: JSON.parse(route?.cord1).map((coord) => ({
                            lat: parseFloat(coord.lat),
                            lng: parseFloat(coord.lng),
                          })),
                          strokeColor: "#1abc9c",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillOpacity: 0,
                        }}
                      />
                    )
                )
              )}

              {this.state.filterToggleReturns &&
                data?.returns?.map((ret, index) => (
                  <MarkerF
                    key={`RET${index}`}
                    position={JSON.parse(ret.customer.geo_location)}
                    icon={{
                      url: constants.marker_black,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: "R",
                      color: "white",
                      fontSize: "10",
                    }}
                    onClick={() => {
                      alertInfoSilent(
                        ret.customer.shop_name + " (View)",
                        3000,
                        () => this.setState({ selectedCustomer: ret.customer })
                      );
                      return false;
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                        JSON.parse(ret.customer.geo_location).lat
                      },${
                        JSON.parse(ret.customer.geo_location).lng
                      }&travelmode=motorcycle`)
                    }
                  />
                ))}

              {data?.delivery?.map((sale, index) => (
                <MarkerF
                  key={`D${index}`}
                  position={JSON.parse(sale.customer.geo_location)}
                  icon={{
                    url: constants.marker_red,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: "D",
                    color: "white",
                    fontSize: "10",
                  }}
                  onClick={() => {
                    alertInfoSilent(
                      sale.customer.shop_name + " (View)",
                      3000,
                      () => this.setState({ selectedCustomer: sale.customer })
                    );
                    return false;
                  }}
                  onDblClick={() =>
                    (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                      JSON.parse(sale.customer.geo_location).lat
                    },${
                      JSON.parse(sale.customer.geo_location).lng
                    }&travelmode=motorcycle`)
                  }
                />
              ))}

              <MarkerF
                position={this.props.currentLocation}
                label={{
                  text: "You",
                  fontSize: "10",
                }}
              />
            </GoogleMap>
          </>
        )}

        {/* List Mode */}
        {mode == "list" && (
          <List>
            {data?.delivery?.map((sale, index) => (
              <List.Item key={`deliverylist-${index}`}>
                <CustomerListItem
                  customer={sale.customer}
                  favs={getFavCustomers(() => {})}
                  onFavUpdate={(favs) =>
                    Cookies.set("favs", JSON.stringify(favs), {
                      expires: 365,
                    })
                  }
                  selectCustomer={() => {
                    this.setState({ selectedCustomer: sale.customer });
                  }}
                />
              </List.Item>
            ))}
          </List>
        )}

        {/* Filter Modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.setState({ filterModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Filter List</Divider>
            <i>Delivery Date Filter</i>
            <DatePicker
              oneTap
              block
              editable={false}
              value={this.state.filterDeliveryDate}
              onChange={(filterDeliveryDate) => {
                this.setState({ filterDeliveryDate });
              }}
            />
            <Divider>Options</Divider>
            <Toggle
              size="xs"
              checked={this.state.filterGoogleMapPlaces}
              checkedChildren="Google Places"
              unCheckedChildren="Google Places"
              onChange={(filterGoogleMapPlaces) => {
                this.setState({ filterGoogleMapPlaces });
              }}
            />{" "}
            |
            <Toggle
              size="xs"
              checked={this.state.filterToggleReturns}
              checkedChildren="Hide Returns"
              unCheckedChildren="Show Returns"
              onChange={(filterToggleReturns) => {
                this.setState({ filterToggleReturns });
              }}
            />
          </Modal.Body>
        </Modal>

        {/* Lead & Custoemr View Drawer */}
        {selectedCustomer != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null}
            onClose={() => this.setState({ selectedCustomer: null })}
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Deliveries;
