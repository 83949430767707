import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Modal,
  Loader,
  Input,
  InputNumber,
  DatePicker,
  InputPicker,
  List,
  Stack,
  Placeholder,
  Tag,
  Nav,
  Rate,
  Navbar,
  Toggle,
  Badge,
  Divider,
  Message,
  TagPicker,
  InputGroup,
  SelectPicker,
  Pagination,
  Drawer,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
  haversineDistance,
  shortenedText,
} from "../../../constants";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import FunnelIcon from "@rsuite/icons/Funnel";
import SearchIcon from "@rsuite/icons/Search";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaRectangleList } from "react-icons/fa6";
import { IoTimer } from "react-icons/io5";
import { MdOutlineCreateNewFolder } from "react-icons/md";

import HomeLeadView from "./home-lead-view";
import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import {
  loadHashTags,
  loadRoutes,
  loadRouteWalks,
} from "../../../Loaders/GeneralLoder";
import { checkRoute } from "../../../Helpers/RouteHelper";
import RouteWalker from "../../../Modules/RouteWalker";
import HomeLeadMap from "./home-lead-map";
import {
  findNearestItem,
  getNearestItems,
} from "../../../Helpers/CoordinateHelper";
import {
  bulkCreateLeadFollowup,
  createHashTag,
} from "../../../Loaders/GeneralSaver";
import LeadListItem from "../../../components/LeadListItem";
import LeadFollowupForm from "../../../components/LeadFollowupForm";
import GooglePlaces from "../../../Modules/GoolgePlaces";
import CustomLoader from "../../../Widgets/CustomLoader";
import {
  extractEnglishWords,
  findSuitableHashTags,
} from "../../../Helpers/Utilities";

const followupFilterItems = [
  "All",
  "With Followup",
  "Without Followup",
  "Older than 10 days",
  "Older than 20 days",
  "Older than 30 days",
  "Events All",
  "Events Not Complete",
];
const remarks = [
  "Good client need to visit again",
  "May get interested",
  "Person not there",
  "Financially down",
  "Other brands",
  "No LED sales",
  "Trust issue",
  "Expecting lower price",
].map((item) => ({
  label: item,
  value: item,
}));

class HomeLeads extends Component {
  state = {
    routes: [],
    hashTags: [],
    selectedRouteId: Cookies.get("selectedRouteId") ?? null,
    routeWalks: [],
    leads: [],
    customers: [],
    modal: false,
    filterModal: false,
    view: null,
    dummy: {},
    viewIndex: null,
    edit: false,
    loading: false,
    activeNav: "followup",
    useStreetView: false,
    existingCustomerFilter: JSON.parse(
      Cookies.get("existingCustomerFilter") ?? "false"
    ),
    followupFilter: Cookies.get("followupFilter") ?? "All",
    filterPhone: Cookies.get("filterPhone") ?? "All",
    filterSortBy: Cookies.get("filterSortBy") ?? "Location",
    filterTagIncludes: JSON.parse(Cookies.get("filterTagIncludes") ?? "[]"),
    filterTagNotIncludes: JSON.parse(
      Cookies.get("filterTagNotIncludes") ?? "[]"
    ),
    filterMapCustomers: JSON.parse(
      Cookies.get("filterMapCustomers") ?? "false"
    ),
    filterMapLeads: JSON.parse(Cookies.get("filterMapLeads") ?? "false"),
    filterMapDeepZoom: JSON.parse(Cookies.get("filterMapDeepZoom") ?? "false"),
    filterMapCluster: JSON.parse(Cookies.get("filterMapCluster") ?? "false"),
    filterMapWalks: JSON.parse(Cookies.get("filterMapWalks") ?? "false"),
    filterMapRoutes: JSON.parse(Cookies.get("filterMapRoutes") ?? "true"),
    filterMapMiniMap: JSON.parse(Cookies.get("filterMapMiniMap") ?? "false"),
    filterMapMiniMapEdit: Cookies.get("filterMapMiniMapEdit") ?? false,
    filterMapRouteWalker: JSON.parse(
      Cookies.get("filterMapRouteWalker") ?? "false"
    ),
    mapObject: null,
    quickLeadViewFunction: null,
    quickCustomerViewFunction: null,
    page: 1,
    pageLimit: Cookies.get("pageLimit") ?? 30,
  };

  setDummy = (place = null) => {
    this.setState({
      dummy: {
        geolocation: place
          ? JSON.stringify({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            })
          : null,
        shop_name: place?.name ?? "",
        phone: place?.formatted_phone_number ?? "",
        remarks: !place
          ? ""
          : JSON.stringify(
              findSuitableHashTags(this.state.hashTags, [
                ...place.types,
                ...place.name.split(" "),
              ])
            ),
        next_visit: "",
        notes: "",
        rate: 2,
        address: place
          ? extractEnglishWords(place.formatted_address ?? place.vicinity ?? "")
          : "",
        g_photo: place?.photos ? place.photos[0]?.getUrl() : null,
        g_rating: place?.rating ?? null,
        g_types: JSON.stringify(place?.types ?? null),
        // mapObject: null,
      },
    });
  };

  componentDidMount = () => {
    loadRoutes((routes) => {
      this.setState({ routes, selectedRouteId: routes[0]?.id }, () => {
        this.loadLeads();
        this.loadWalks();
      });
    });
    loadHashTags((hashTags) => this.setState({ hashTags }));
  };

  componentDidUpdate = () => {};

  changeVal = (name, value) => {
    var { view } = this.state;
    view[name] = value;
    this.setState({ view });
  };

  changeDummy = (name, value) => {
    var { dummy } = this.state;
    dummy[name] = value;
    this.setState({ dummy });
  };

  loadLeads = () => {
    var { selectedRouteId } = this.state;

    this.setLoading();
    this.setState({ leads: [] });
    fetch(constants.url + "lead_spots/" + selectedRouteId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        this.setState({
          leads: data.data,
          customers: data.customers,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  loadWalks = () => {
    loadRouteWalks(this.state.selectedRouteId, (routeWalks) => {
      this.setState({ routeWalks });
    });
  };

  leadClick = (viewIndex, view = null) => {
    if (view != null) {
      this.setState({ view });
      return;
    }
    if ((viewIndex < 0) | (viewIndex > this.state.leads.length - 1)) {
      this.setState({ viewIndex: null });
      return;
    }
    var lead = this.filterLeads()[viewIndex];
    this.setState({ view: lead, edit: true, viewIndex });
  };

  modal = (modal = true, place = null) => {
    this.setDummy(place);
    this.setState({ modal, edit: false });

    //MODAL OPEN: CHECK STREET VIEW TRUE, THEN ENABLE USE STREETVIEW CORDS
    if (modal) {
      this.setState({
        useStreetView: this.state?.mapObject?.getStreetView()?.getPosition()
          ? true
          : false,
      });
    }
  };

  filterModal = (filterModal = true) => {
    this.setState({ filterModal });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  saveLead = () => {
    this.setLoading();
    var { view, edit, dummy, useStreetView } = this.state;
    var data = dummy;

    if (data.geolocation == null) {
      data.geolocation = JSON.stringify(this.getCoordinate());
    }

    data.route_id = checkRoute(
      typeof data.geolocation === "string"
        ? JSON.parse(data.geolocation)
        : data.geolocation,
      this.state.routes
    )?.id;

    fetch(constants.url + "lead_spots", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        if (data.success == false) {
          alertError(data.message);
        } else {
          var { leads } = this.state;
          leads.push(data.data);
          this.setState({
            leads,
            view: !useStreetView ? data.data : null,
            modal: false,
            viewIndex: this.state.leads.length - 1,
          });
          alertInfo(`"Created Lead, (View)`, 5000, () => {
            if (this.state.quickLeadViewFunction != null) {
              this.state.quickLeadViewFunction(data.data);
            } else {
              this.setState({ view: data.data });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        this.modal(false);
        alertError(`Unable to ${edit ? "Save" : "Create"} Lead, try again`);
      });
  };

  getCoordinate = () => {
    var { useStreetView } = this.state;
    var streetViewPosition = this.state?.mapObject
      ?.getStreetView()
      ?.getPosition();
    if (streetViewPosition && useStreetView) {
      const streetViewCoordinates = {
        lat: streetViewPosition.lat(),
        lng: streetViewPosition.lng(),
      };

      return streetViewCoordinates;
    }

    return this.props.currentLocation;
  };

  filterLeads = (pagination = true) => {
    var {
      leads,
      followupFilter,
      filterTagIncludes,
      filterTagNotIncludes,
      filterSortBy,
      filterEventDate,
      filterEventType,
      page,
      pageLimit,
    } = this.state;

    if (leads == null) {
      return [];
    }

    if (filterSortBy == "Location") {
      var referenceCoord = this.props.currentLocation;
      if (this.state.mapObject) {
        const mapCenter = this.state.mapObject.getCenter();
        referenceCoord = {
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        };
      }

      leads = leads.sort((lead1, lead2) => {
        const distanceToLead1 = haversineDistance(
          referenceCoord,
          JSON.parse(lead1.geolocation)
        );
        const distanceToLead2 = haversineDistance(
          referenceCoord,
          JSON.parse(lead2.geolocation)
        );
        return distanceToLead1 - distanceToLead2;
      });
    }

    if (this.state.searchValue && this.state.searchValue?.length > 0) {
      leads = leads.filter(
        (lead) =>
          lead.shop_name
            ?.toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          lead.phone?.includes(this.state.searchValue)
      );
    }

    //phone filter
    if (this.state.filterPhone == "With Phone") {
      leads = leads.filter((lead) => lead.phone != null);
    } else if (this.state.filterPhone == "Without Phone") {
      leads = leads.filter(
        (lead) => lead.phone == null || lead.phone?.length == 0
      );
    }

    //existing customer filter
    if (this.state.existingCustomerFilter) {
      leads = leads.filter((lead) => lead.customer != null);
    } else {
      leads = leads.filter((lead) => lead.customer == null);
    }

    // followup filters ["All", "With Followup", "Without Followup", "Older than 10 days", "Older than 20 days", "Older than 30 days", "Events All", "Events Not Complete"];
    if (followupFilter == followupFilterItems[1]) {
      leads = leads.filter((lead) => lead.last_followup != null);
    } else if (followupFilter == followupFilterItems[2]) {
      leads = leads.filter((lead) => lead.last_followup == null);
    } else if (followupFilter == followupFilterItems[3]) {
      leads = leads.filter(
        (lead) =>
          lead.last_followup &&
          moment().diff(moment(lead.last_followup.created_at), "days") >= 10
      );
    } else if (followupFilter == followupFilterItems[4]) {
      leads = leads.filter(
        (lead) =>
          lead.last_followup &&
          moment().diff(moment(lead.last_followup.created_at, "days")) >= 20
      );
    } else if (followupFilter == followupFilterItems[5]) {
      leads = leads.filter(
        (lead) =>
          lead.last_followup &&
          moment().diff(moment(lead.last_followup.created_at, "days")) >= 30
      );
    } else if (
      followupFilter == followupFilterItems[6] ||
      followupFilter == followupFilterItems[7]
    ) {
      leads = leads.filter(
        (lead) => lead.last_followup && lead.last_followup.event
      );

      if (followupFilter == followupFilterItems[7]) {
        leads = leads.filter((lead) => !lead.last_followup.event_done);
      }

      if (filterEventDate) {
        leads = leads.filter((lead) =>
          moment(lead.last_followup.event_date).isSame(
            moment(filterEventDate),
            "day"
          )
        );
      }
    }

    if (filterEventType) {
      leads = leads.filter(
        (lead) => lead.last_followup?.event_type == filterEventType
      );
    }

    leads = leads.filter((lead) => {
      if (lead.remarks === null) {
        return filterTagIncludes.length === 0;
      }
      const includesTags =
        filterTagIncludes.length === 0 ||
        filterTagIncludes.every((tag) => lead.remarks.includes(tag));
      const notIncludesTags =
        filterTagNotIncludes.length === 0 ||
        filterTagNotIncludes.every((tag) => !lead.remarks.includes(tag));
      return includesTags && notIncludesTags;
    });

    //pagination..
    leads = pagination
      ? leads?.slice((page - 1) * pageLimit, page * pageLimit)
      : leads;

    return leads;
  };

  render() {
    var { routes, routeWalks, selectedRouteId, customers, mapObject } =
      this.state;
    var {
      view,
      dummy,
      viewIndex,
      edit,
      loading,
      activeNav,
      filterPhone,
      existingCustomerFilter,
      followupFilter,
    } = this.state;

    var leads = this.filterLeads();

    var eligibleFollowups = this.state.leads
      .filter(
        (lead) =>
          lead.next_followup_date != null &&
          moment(lead.next_followup_date).isSameOrBefore(moment(), "day")
      )
      .sort((a, b) =>
        moment(a.next_followup_date).diff(moment(b.next_followup_date))
      );

    var selectedRoute = routes.find((r) => r.id == selectedRouteId);
    var actualRoute = checkRoute(this.getCoordinate(), routes);

    var nearestLeads = getNearestItems(
      this.getCoordinate(),
      this.state.leads,
      "geolocation",
      3
    );
    var nearestCustomers = getNearestItems(
      this.getCoordinate(),
      customers,
      "geo_location",
      3
    );

    return (
      <div>
        <CustomLoader full text="Loading lead data" loading={loading} />
        <Navbar appearance="subtle">
          <Nav
            activeKey={activeNav}
            onSelect={(activeNav) => this.setState({ activeNav })}
            justified
            defaultActiveKey="followup"
            appearance="subtle"
          >
            <Nav.Item
              eventKey={activeNav}
              icon={<FunnelIcon />}
              onClick={this.filterModal}
            ></Nav.Item>
            <Nav.Item eventKey="followup">
              <Badge maxCount={1000} content={eligibleFollowups.length}>
                <IoTimer />
              </Badge>
            </Nav.Item>
            <Nav.Item
              onClick={() => this.setState({ listDrawer: true })}
              eventKey="map"
            >
              <Badge maxCount={9999} content={this.filterLeads(false).length}>
                <FaRectangleList />
              </Badge>
            </Nav.Item>
            <Nav.Item eventKey="map">
              <FaMapLocationDot />
            </Nav.Item>
          </Nav>
          <Nav pullRight>
            <Nav.Item>
              <GooglePlaces
                location={
                  this.state.mapObject?.getCenter() ?? this.getCoordinate()
                }
                leadFromPlace={(place) => this.modal(true, place)}
                setPlaceMarkers={(placeMarkers) =>
                  this.setState({ placeMarkers })
                }
                customers={this.state.customers}
                leads={this.state.leads}
                selectedRoute={selectedRoute}
                hashTags={this.state.hashTags}
                refreshLeads={this.loadLeads}
              />
            </Nav.Item>
            <Nav.Item icon={<AddOutlineIcon />} onClick={this.modal}></Nav.Item>
            <Nav.Item icon={<ReloadIcon />} onClick={this.loadLeads}></Nav.Item>
          </Nav>
        </Navbar>
        <Stack justifyContent="space-around">
          {actualRoute?.id == selectedRoute?.id ? (
            <Tag size="xs">Route: {selectedRoute?.route_name}</Tag>
          ) : (
            <Tag size="xs" color="orange">
              {selectedRoute?.route_name} |{" "}
              {actualRoute?.route_name ?? "No Route!"}
            </Tag>
          )}
          <Tag size="xs">Customers: {customers.length}</Tag>
          <Tag size="xs">Walks: {routeWalks.length}</Tag>
        </Stack>

        {activeNav == "map" && (
          <HomeLeadMap
            mapObject={mapObject}
            leads={leads}
            onLoad={(mapObject) => {
              this.setState({ mapObject });
              mapObject.setCenter(JSON.parse(leads[0]?.geolocation));
            }}
            onUnmount={() => this.setState({ mapObject: null })}
            customers={customers}
            selectedRouteId={selectedRouteId}
            routes={routes}
            routeWalks={routeWalks}
            currentLocation={this.getCoordinate() ?? this.props.currentLocation}
            loadWalks={this.loadWalks}
            selectedRoute={selectedRoute}
            actualRoute={actualRoute}
            sessionCheck={this.props.sessionCheck}
            changeVal={this.changeVal}
            remarks={remarks}
            saveLead={this.saveLead}
            loadLeads={this.loadLeads}
            filterMapCustomers={this.state.filterMapCustomers}
            filterMapLeads={this.state.filterMapLeads}
            filterMapDeepZoom={this.state.filterMapDeepZoom}
            filterMapCluster={this.state.filterMapCluster}
            filterMapWalks={this.state.filterMapWalks}
            filterMapRoutes={this.state.filterMapRoutes}
            filterMapMiniMap={this.state.filterMapMiniMap}
            filterMapMiniMapEdit={this.state.filterMapMiniMapEdit}
            filterMapRouteWalker={this.state.filterMapRouteWalker}
            quickLeadViewFunction={(quickLeadViewFunction) =>
              this.setState({ quickLeadViewFunction })
            }
            quickCustomerViewFunction={(quickCustomerViewFunction) =>
              this.setState({ quickCustomerViewFunction })
            }
            nearestCustomers={nearestCustomers}
            nearestLeads={nearestLeads}
            hashTags={this.state.hashTags}
            setHashTags={(hashTags) => this.setState({ hashTags })}
            placeMarkers={this.state.placeMarkers}
          />
        )}

        {activeNav == "followup" &&
          eligibleFollowups.map((lead, index) => {
            return (
              <LeadListItem
                lead={lead}
                key={index}
                index={index}
                onClick={() => {
                  this.setState({ view: lead });
                }}
              />
            );
          })}

        {/* Create Lead Modal */}
        <Modal
          open={this.state.modal}
          onClose={() => this.modal(false)}
          backdrop={false}
        >
          <Modal.Header>
            <Modal.Title>Create Lead Spot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack justifyContent="space-between">
              <i>Use Street View coordinates?</i>
              <Toggle
                checked={this.state.useStreetView}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(value) => this.setState({ useStreetView: value })}
              />
            </Stack>
            {dummy.g_photo && (
              <>
                <br />
                <img
                  src={dummy.g_photo}
                  style={{ width: "auto", height: "80px" }}
                  data-action="zoom"
                />
              </>
            )}
            <br />
            <Divider>
              Route: {actualRoute?.route_name ?? "Unable to find route"}
            </Divider>
            <br />

            <Input
              placeholder="Shop name"
              block
              onChange={(value) => {
                this.changeDummy("shop_name", value);
              }}
              value={dummy.shop_name}
            />
            <InputNumber
              placeholder="Contact number"
              block
              onChange={(value) => {
                this.changeDummy("phone", value);
              }}
              value={dummy.phone}
            />
            <br />
            <Input
              placeholder="Notes"
              block
              onChange={(value) => {
                this.changeDummy("notes", value);
              }}
              value={dummy.notes}
            />
            <br />
            <TagPicker
              creatable
              data={this.state.hashTags?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onCreate={(hashTags) => {
                createHashTag(hashTags[hashTags.length - 1], (newHashTags) => {
                  this.setState({ hashTags: newHashTags });
                });
              }}
              block
              placeholder="Remarks"
              onChange={(value) => {
                this.changeDummy("remarks", JSON.stringify(value));
              }}
              value={JSON.parse(
                dummy?.remarks?.startsWith("[")
                  ? dummy?.remarks
                  : dummy?.remarks?.length > 0
                  ? `["${dummy?.remarks}"]`
                  : "[]"
              )}
            />
            <Rate
              onChange={(value) => {
                this.changeDummy("rate", value);
              }}
              onChangeActive={(value) => {
                this.changeDummy("rate", value);
              }}
              value={dummy.rate}
            />
            <br />
            {/* {nearestLead[0]?.nearestItem && nearestLead?.shortestDistance < 40 && (
              <Message type="error">
                <strong>Lead:</strong>
                {nearestLead?.nearestItem?.shop_name} is{" "}
                {nearestLead?.shortestDistance} Meters away
                {this.state.quickLeadViewFunction != null && (
                  <Button
                    appearance="link"
                    onClick={() =>
                      this.state.quickLeadViewFunction(nearestLead?.nearestItem)
                    }
                  >
                    View
                  </Button>
                )}
              </Message>
            )}
            {nearestCustomer?.nearestItem &&
              nearestCustomer?.shortestDistance < 40 && (
                <Message type="error">
                  <strong>Customer:</strong>
                  {nearestCustomer?.nearestItem?.shop_name} is{" "}
                  {nearestCustomer?.shortestDistance} Meters away
                  {this.state.quickCustomerViewFunction != null && (
                    <Button
                      appearance="link"
                      onClick={() =>
                        this.state.quickCustomerViewFunction(
                          nearestCustomer?.nearestItem
                        )
                      }
                    >
                      View
                    </Button>
                  )}
                </Message>
              )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.saveLead}
              disabled={loading}
              appearance="primary"
            >
              Save
            </Button>
            <Button onClick={() => this.modal(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* FIlter modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.filterModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Select Route</Divider>
            <Stack>
              <InputPicker
                style={{ width: "100px" }}
                size="sm"
                placeholder="Select route"
                value={selectedRouteId}
                data={routes?.map((item, index) => ({
                  label: item.route_name,
                  value: item.id,
                }))}
                onChange={(selectedRouteId) => {
                  this.setState({ selectedRouteId }, () => {
                    this.loadLeads();
                    this.loadWalks();
                  });
                  this.filterModal(false);
                  Cookies.set("selectedRouteId", selectedRouteId);
                }}
                disabled={this.state.loading}
              />
            </Stack>
            <Divider>More Filters</Divider>
            <i>Filter phone:</i>
            <InputPicker
              block
              data={["All", "With Phone", "Without Phone"].map((item) => ({
                label: item,
                value: item,
              }))}
              value={this.state.filterPhone}
              onChange={(filterPhone) => {
                this.setState({ filterPhone });
                Cookies.set("filterPhone", filterPhone);
              }}
            />

            <i>Filter Last Followup:</i>
            <InputPicker
              block
              data={followupFilterItems.map((item) => ({
                label: item,
                value: item,
              }))}
              value={this.state.followupFilter}
              onChange={(followupFilter) => {
                this.setState({ followupFilter });
                Cookies.set("followupFilter", followupFilter);
              }}
            />

            {this.state.followupFilter == followupFilterItems[6] ||
            this.state.followupFilter == followupFilterItems[7] ? (
              <>
                <i>Event Type</i>
                <SelectPicker
                  size="sm"
                  style={{ width: 224 }}
                  searchable={false}
                  data={constants.event_types.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  value={this.state.filterEventType}
                  onChange={(filterEventType) => {
                    {
                      this.setState({ filterEventType });
                      Cookies.set("filterEventType", filterEventType);
                    }
                  }}
                />
                <br />
                <i>Event Date Filter</i>
                <DatePicker
                  oneTap
                  block
                  editable={false}
                  value={this.state.filterEventDate}
                  onChange={(filterEventDate) => {
                    this.setState({ filterEventDate });
                    Cookies.set("filterEventDate", filterEventDate);
                  }}
                />
              </>
            ) : (
              ""
            )}

            <i>Tag Includes</i>
            <TagPicker
              block
              value={this.state.filterTagIncludes}
              onChange={(filterTagIncludes) => {
                this.setState({ filterTagIncludes });
                Cookies.set(
                  "filterTagIncludes",
                  JSON.stringify(filterTagIncludes)
                );
              }}
              data={this.state.hashTags?.map((hashTag) => ({
                label: hashTag.name,
                value: hashTag.name,
              }))}
            />

            <i>Tag Not Includes</i>
            <TagPicker
              block
              value={this.state.filterTagNotIncludes}
              onChange={(filterTagNotIncludes) => {
                this.setState({ filterTagNotIncludes });
                Cookies.set(
                  "filterTagNotIncludes",
                  JSON.stringify(filterTagNotIncludes)
                );
              }}
              data={this.state.hashTags?.map((hashTag) => ({
                label: hashTag.name,
                value: hashTag.name,
              }))}
            />

            <i>Sort List</i>
            <InputPicker
              block
              data={["Id", "Location", "Last Followup"].map((item) => ({
                label: item,
                value: item,
              }))}
              value={this.state.filterSortBy}
              onChange={(filterSortBy) => {
                this.setState({ filterSortBy });
                Cookies.set("filterSortBy", filterSortBy);
              }}
            />

            <i>Search name or phone:</i>
            <InputGroup>
              <Input
                value={this.state.tempSearchValue}
                onChange={(tempSearchValue) =>
                  this.setState({ tempSearchValue })
                }
              />
              <InputGroup.Button
                onClick={() =>
                  this.setState({ searchValue: "", tempSearchValue: "" })
                }
              >
                X
              </InputGroup.Button>
              <InputGroup.Button
                onClick={() =>
                  this.setState({ searchValue: this.state.tempSearchValue })
                }
              >
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
            <br />
            <Stack divider=" | ">
              <Toggle
                size="xs"
                checked={this.state.existingCustomerFilter}
                checkedChildren="Existing Customers"
                unCheckedChildren="Non Existing in Customers"
                onChange={(existingCustomerFilter) => {
                  this.setState({ existingCustomerFilter });
                  Cookies.set("existingCustomerFilter", existingCustomerFilter);
                }}
              />
            </Stack>
            <Divider>Map Filters</Divider>
            <Toggle
              size="xs"
              checked={this.props.filterMapCustomers}
              checkedChildren="Customers"
              unCheckedChildren="Customers"
              onChange={(filterMapCustomers) => {
                this.setState({ filterMapCustomers });
                Cookies.set("filterMapCustomers", filterMapCustomers);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapLeads}
              checkedChildren="Leads"
              unCheckedChildren="Leads"
              onChange={(filterMapLeads) => {
                this.setState({ filterMapLeads });
                Cookies.set("filterMapLeads", filterMapLeads);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapDeepZoom}
              checkedChildren="Deep Zoom"
              unCheckedChildren="Deep Zoom"
              onChange={(filterMapDeepZoom) => {
                this.setState({ filterMapDeepZoom });
                Cookies.set("filterMapDeepZoom", filterMapDeepZoom);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapCluster}
              checkedChildren="Cluster"
              unCheckedChildren="Cluster"
              onChange={(filterMapCluster) => {
                this.setState({ filterMapCluster });
                Cookies.set("filterMapCluster", filterMapCluster);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapWalks}
              checkedChildren="Walks"
              unCheckedChildren="Walks"
              onChange={(filterMapWalks) => {
                this.setState({ filterMapWalks });
                Cookies.set("filterMapWalks", filterMapWalks);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapRoutes}
              checkedChildren="Routes"
              unCheckedChildren="Routes"
              onChange={(filterMapRoutes) => {
                this.setState({ filterMapRoutes });
                Cookies.set("filterMapRoutes", filterMapRoutes);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapMiniMap}
              checkedChildren="MiniMap"
              unCheckedChildren="MiniMap"
              onChange={(filterMapMiniMap) => {
                this.setState({ filterMapMiniMap });
                Cookies.set("filterMapMiniMap", filterMapMiniMap);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapMiniMapEdit}
              checkedChildren="MiniMap-Edit"
              unCheckedChildren="MiniMap-Edit"
              onChange={(filterMapMiniMapEdit) => {
                this.setState({ filterMapMiniMapEdit });
                Cookies.set("filterMapMiniMapEdit", filterMapMiniMapEdit);
              }}
            />
            <Toggle
              size="xs"
              checked={this.state.filterMapRouteWalker}
              checkedChildren="Route Walker"
              unCheckedChildren="Route Walker"
              onChange={(filterMapRouteWalker) => {
                this.setState({ filterMapRouteWalker });
                Cookies.set("filterMapRouteWalker", filterMapRouteWalker);
              }}
            />
            <Divider>Pagination</Divider>
            <i>Pagination limit</i>
            <InputNumber
              value={this.state.pageLimit}
              onChange={(pageLimit) => {
                this.setState({ pageLimit });
                Cookies.set("pageLimit", pageLimit);
              }}
            />
          </Modal.Body>
        </Modal>

        {/* Lead view drawer */}
        {view != null && (
          <Drawer
            open={view != null}
            onClose={() => this.setState({ view: null })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>View Lead</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <HomeLeadView
                lead={view}
                back={() => {
                  this.setState({ view: null, viewIndex: null });
                }}
                remove={() => {
                  this.setState({
                    leads: this.state.leads.filter(
                      (lead) => lead.id !== view.id
                    ),
                  });
                }}
                next={() => this.leadClick(viewIndex + 1)}
                previous={() => this.leadClick(viewIndex - 1)}
                changeVal={this.changeVal}
                remarks={remarks}
                saveLead={this.saveLead}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(view) => this.setState({ view })}
                routes={this.state.routes}
                loadLeads={this.loadLeads}
              />
            </Drawer.Body>
          </Drawer>
        )}

        {/* //Leads List drawer */}
        {this.state.listDrawer && (
          <Drawer
            open={this.state.listDrawer}
            onClose={() => this.setState({ listDrawer: false })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>Leads</Drawer.Title>
              <Drawer.Actions>
                <Button
                  onClick={() => this.setState({ bulkFollowupDrawer: true })}
                >
                  <MdOutlineCreateNewFolder /> &nbsp; Bulk Followup
                </Button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body id="scrollable">
              {leads &&
                leads.length != 0 &&
                leads.map((leadItem, index) => {
                  return (
                    <LeadListItem
                      lead={leadItem}
                      key={index}
                      index={index}
                      onClick={() => {
                        this.setState({ viewIndex: index });
                        this.leadClick(index, view);
                      }}
                    />
                  );
                })}
              <Divider />
              <Pagination
                size="xs"
                total={this.filterLeads(false).length}
                limit={this.state.pageLimit}
                activePage={this.state.page}
                onChangePage={(page) => {
                  this.setState({ page });
                  // window.scrollTo(0, 0);
                  document.getElementById("scrollable").scrollTo(0, 0);
                }}
                prev
                last
                next
                first
                maxButtons={5}
              />
            </Drawer.Body>
          </Drawer>
        )}

        {/* // Bulk followup drawer.. */}
        {this.state.bulkFollowupDrawer && (
          <Drawer
            open={this.state.bulkFollowupDrawer}
            onClose={() => this.setState({ bulkFollowupDrawer: false })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>
                Create Followup for {leads.length} leads
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <LeadFollowupForm
                onClickSave={(followup) =>
                  bulkCreateLeadFollowup(followup, leads, () => {})
                }
                lead={leads[0]} //any one lead to get the route id for loading route users..
                sessionCheck={this.props.sessionCheck}
              />
            </Drawer.Body>
          </Drawer>
        )}
      </div>
    );
  }
}

export default HomeLeads;
